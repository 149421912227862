export const PERMISSIONS = [
  {key: '*', value: '*', text: 'ALL'},
  {key: 'approveContent', value: 'approveContent', text: 'APPROVE CONTENT'},
  {key: 'createAccount', value: 'createAccount', text: 'CREATE ACCOUNT'},
  {key: 'createChannel', value: 'createChannel', text: 'CREATE CHANNEL'},
  {key: 'createContent', value: 'createContent', text: 'CREATE CONTENT'},
  {key: 'createDatasource', value: 'createDatasource', text: 'CREATE DATASOURCE'},
  {key: 'createWarehouse', value: 'createWarehouse', text: 'CREATE WAREHOUSE'},
  {key: 'createFolder', value: 'createFolder', text: 'CREATE FOLDER'},
  {key: 'createLabel', value: 'createLabel', text: 'CREATE LABEL'},
  {key: 'createShop', value: 'createShop', text: 'CREATE SHOP'},
  {key: 'createUser', value: 'createUser', text: 'CREATE USER'},
  {key: 'editContentName', value: 'editContentName', text: 'EDIT CONTENTNAME'},
  {key: 'editDevMode', value: 'editDevMode', text: 'EDIT DEV MODE'},
  {key: 'debugContent', value: 'debugContent', text: 'DEBUG CONTENT'},
  {key: 'debugDevice', value: 'debugDevice', text: 'DEBUG DEVICE'},
  {key: 'publishContent', value: 'publishContent', text: 'PUBLISH CONTENT'},
  {key: 'releaseContent', value: 'releaseContent', text: 'RELEASE CONTENT'},
  {key: 'registerDevice', value: 'registerDevice', text: 'REGISTER DEVICE'},
  {key: 'releaseAppVersion', value: 'releaseAppVersion', text: 'RELEASE APPVERSION'},
  {key: 'removeChannel', value: 'removeChannel', text: 'REMOVE CHANNEL'},
  {key: 'removeContent', value: 'removeContent', text: 'REMOVE CONTENT'},
  {key: 'removeDatasource', value: 'removeDatasource', text: 'REMOVE DATASOURCE'},
  {key: 'removeWarehouse', value: 'removeWarehouse', text: 'REMOVE WAREHOUSE'},
  {key: 'removeDevice', value: 'removeDevice', text: 'REMOVE DEVICE'},
  {key: 'removeCache', value: 'removeCache', text: 'REMOVE DEVICE CACHE'},
  {key: 'removeMedia', value: 'removeMedia', text: 'REMOVE MEDIA'},
  {key: 'removeShop', value: 'removeShop', text: 'REMOVE SHOP'},
  {key: 'requestContentApproval', value: 'requestContentApproval', text: 'REQUEST CONTENT APPROVAL'},
  {key: 'rpcDevice', value: 'rpcDevice', text: 'RPC DEVICE'},
  {key: 'saveContent', value: 'saveContent', text: 'SAVE CONTENT'},
  {key: 'searchLocation', value: 'searchLocation', text: 'SEARCH LOCATION'},
  {key: 'takeScreenShot', value: 'takeScreenShot', text: 'TAKE SCREENSHOT'},
  {key: 'testContent', value: 'testContent', text: 'TEST CONTENT'},
  {key: 'updateChannel', value: 'updateChannel', text: 'UPDATE CHANNEL'},
  {key: 'updateSchedule', value: 'updateSchedule', text: 'UPDATE CHANNELSCHEDULE'},
  {key: 'updateDatasource', value: 'updateDatasource', text: 'UPDATE DATASOURCE'},
  {key: 'updateWarehouse', value: 'updateWarehouse', text: 'UPDATE WAREHOUSE'},
  {key: 'updateDevice', value: 'updateDevice', text: 'UPDATE DEVICE'},
  {key: 'updateDeviceAttribute', value: 'updateDeviceAttribute', text: 'UPDATE DEVICE ATTRIBUTE'},
  {key: 'updateDeviceDefaultChannel', value: 'updateDeviceDefaultChannel', text: 'UPDATE DEVICE DEFAULT CHANNEL'},
  {key: 'updateMediaMeta', value: 'updateMediaMeta', text: 'UPDATE MEDIAMETA'},
  {key: 'updateShop', value: 'updateShop', text: 'UPDATE SHOP'},
  {key: 'updateShopAttribute', value: 'updateShopAttribute', text: 'UPDATE SHOP ATTRIBUTE'},
  {key: 'uploadMedia', value: 'uploadMedia', text: 'UPLOAD MEDIA'},
  {key: 'uploadWarehouseData', value: 'uploadWarehouseData', text: 'UPLOAD WAREHOUSEDATA'},
  {key: 'viewAccount', value: 'viewAccount', text: 'VIEW ACCOUNT'},
  {key: 'viewAccountList', value: 'viewAccountList', text: 'VIEW ACCOUNTLIST'},
  {key: 'viewAnyList', value: 'viewAnyList', text: 'VIEW ANY LIST'},
  {key: 'viewChannel', value: 'viewChannel', text: 'VIEW CHANNEL'},
  {key: 'viewChannelList', value: 'viewChannelList', text: 'VIEW CHANNELLIST'},
  {key: 'viewChannelReport', value: 'viewChannelReport', text: 'VIEW CHANNELREPORT'},
  {key: 'viewChannelSchedules', value: 'viewChannelSchedules', text: 'VIEW CHANNELSCHEDULE'},
  {key: 'viewContent', value: 'viewContent', text: 'VIEW CONTENT'},
  {key: 'viewContentChannels', value: 'viewContentChannels', text: 'VIEW CONTENTCHANNELS'},
  {key: 'viewContentList', value: 'viewContentList', text: 'VIEW CONTENTLIST'},
  {key: 'viewDashboard', value: 'viewDashboard', text: 'VIEW DASHBOARD'},
  {key: 'viewDatasource', value: 'viewDatasource', text: 'VIEW DATASOURCE'},
  {key: 'viewDatasourceList', value: 'viewDatasourceList', text: 'VIEW DATASOURCELIST'},
  {key: 'viewWarehouse', value: 'viewWarehouse', text: 'VIEW WAREHOUSE'},
  {key: 'viewWarehouseList', value: 'viewWarehouseList', text: 'VIEW WAREHOUSELIST'},
  {key: 'viewDevice', value: 'viewDevice', text: 'VIEW DEVICE'},
  {key: 'viewDeviceList', value: 'viewDeviceList', text: 'VIEW DEVICELIST'},
  {key: 'viewFolderList', value: 'viewFolderList', text: 'VIEW FOLDERLIST'},
  {key: 'viewLabelList', value: 'viewLabelList', text: 'VIEW LABELLIST'},
  {key: 'viewLocationList', value: 'viewLocationList', text: 'VIEW LOCATIONLIST'},
  {key: 'viewMedia', value: 'viewMedia', text: 'VIEW MEDIA'},
  {key: 'viewMediaInFolder', value: 'viewMediaInFolder', text: 'VIEW MEDIAS INFOLDER'},
  {key: 'viewMediaList', value: 'viewMediaList', text: 'VIEW MEDIALIST'},
  {key: 'viewShop', value: 'viewShop', text: 'VIEW SHOP'},
  {key: 'viewShopList', value: 'viewShopList', text: 'VIEW SHOPLIST'},
  {key: 'viewUserList', value: 'viewUserList', text: 'VIEW USERLIST'},
  {key: 'viewOtherUsers', value: 'viewOtherUsers', text: 'VIEW OTHERUSERS'},
  {key: 'viewAddonList', value: 'viewAddonList', text: 'VIEW ADDONLIST'},
  {key: 'viewAddon', value: 'viewAddon', text: 'VIEW ADDON'},
  {key: 'viewWidgetList', value: 'viewWidgetList', text: 'VIEW WIDGETLIST'},
  {key: 'viewWidget', value: 'viewWidget', text: 'VIEW WIDGET'},
  {key: 'deviceAdministration', value: 'deviceAdministration', text: 'DEVICE ADMINISTRATION'},
  {key: 'getChannelReport', value: 'getChannelReport', text: 'GET CHANNEL REPORT'},
  {key: 'viewIntegrationList', value: 'viewIntegrationList', text: 'VIEW INTEGRATION LIST '},
  {key: 'removeIntegration', value: 'removeIntegration', text: 'REMOVE INTEGRATION'},
  {key: 'createIntegration', value: 'createIntegration', text: 'CREATE INTEGRATION'},
];
