import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as AccountActions from '../../../actions/account';
import * as UserActions from '../../../actions/user';
import Form from './form';

class AccountAddExistingUser extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    accountActions: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const accountId = parseInt(props.match.params.accountId, 10);
    const state = {
      accountId,
      raw: {
        userId: null,
        roleId: null,
      },
    };

    if (accountId && props.account[accountId]) {
      state.account = props.account[accountId];
    }

    this.state = state;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {accountId} = this.state;
    const {accountActions} = this.props;
    accountActions.get(accountId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState((prevState) => ({account: nextProps.account[prevState.accountId]}));
  }

  onSubmit(values) {
    const {userActions, history} = this.props;
    const user = {...values, accountId: this.state.accountId};

    if (!user.userId) return console.log('User is required');
    if (!user.roleId) return console.log('Role is required');

    return userActions
      .addExistingUserToAccount(user.userId, user.accountId, user.roleId)
      .then((isValid) => isValid && history.push(`/account/${this.state.accountId}`));
  }

  render() {
    const {raw, account} = this.state;

    if (!raw || !account) {
      return <div>...</div>;
    }

    return (
      <div>
        <h1>Add New User to {account.name}</h1>
        <Form initialValues={raw} onSubmit={(values) => this.onSubmit(values)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(AccountActions, dispatch),
  userActions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountAddExistingUser);
