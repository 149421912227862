import API from '../lib/api';
import {handleAPIError} from '../lib/utils';

export function addToAccount(widgetId, accountId) {
  return (dispatch) =>
    API.getInstance()
      .post(`/account/${accountId}/widgets/${widgetId}`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function removeFromAccount(widgetId, accountId) {
  return (dispatch) =>
    API.getInstance()
      .delete(`/account/${accountId}/widgets/${widgetId}`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function listAvailables(accountId) {
  return (dispatch) =>
    API.getInstance()
      .get(`/account/${accountId}/widgets/availables`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}
