import _ from 'lodash';
import React, {Component} from 'react';
import moment from 'moment';
import {DatesRangeInput} from 'semantic-ui-calendar-react';

const DEFAULT_MONTH_RANGE = 6;

class ReportRangeSelector extends Component {
  state = {
    value: '',
  };

  constructor(props) {
    super(props);

    this.timeFormat = 'DD.MM.YYYY';

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  componentDidMount() {
    const {initialValue} = this.props;
    const defaultRange = this.getDefaultRange();

    if (initialValue && initialValue !== defaultRange.boundaries) {
      const [start, end] = initialValue.split(' - ');

      const range = {
        ranges: [{start, end}],
        boundaries: initialValue,
      };

      this.setState({value: range.boundaries});
      this.triggerChangeCallback(range);

      return;
    }

    this.resetToDefaultRange();
  }

  getDefaultRange() {
    // Default range is last 5 months + days of current month.
    const today = moment();

    let months = _.range(DEFAULT_MONTH_RANGE - 1, 0).map((distance) => {
      const month = moment(today).subtract(distance, 'months');
      const firstDay = moment(month).date(1);
      const lastDay = moment(month).endOf('month');

      return {
        start: firstDay.format(this.timeFormat),
        end: lastDay.format(this.timeFormat),
      };
    });

    months.push({
      start: moment(today).date(1).format(this.timeFormat),
      end: today.format(this.timeFormat),
    });

    const boundaries = `${months[0].start} - ${months[months.length - 1].end}`;

    return {
      ranges: months,
      boundaries,
    };
  }

  resetToDefaultRange() {
    const defaultRange = this.getDefaultRange();
    this.setState({value: defaultRange.boundaries});
    this.triggerChangeCallback(defaultRange);
  }

  onChange(event, data) {
    const {value} = data;
    this.setState({value});

    const match = value.match(/(.+) - (.+)/);

    if (!match) return;

    const start = moment(match[1], this.timeFormat, true); // true = Use strict parsing.
    const end = moment(match[2], this.timeFormat, true); // true = Use strict parsing.

    // Dates should be valid.
    if (!start.isValid() || !end.isValid()) return;
    // Range should be valid.
    if (!end.isAfter(start)) return;
    // Years should be after 2000.
    if (start.year() < 2000) return;

    this.triggerChangeCallback({
      boundaries: value,
      ranges: [
        {
          start: match[1],
          end: match[2],
        },
      ],
    });
  }

  onBlur() {
    if (!this.state.value) this.resetToDefaultRange();
  }

  onClear() {
    this.resetToDefaultRange();
  }

  triggerChangeCallback(value) {
    if (typeof this.props.onRangeChange === 'function') {
      this.props.onRangeChange(value);
    }
  }

  render() {
    const {value} = this.state;

    return (
      <div className="device-usage-range-container">
        <DatesRangeInput
          maxDate={moment()}
          dateFormat="DD.MM.YYYY"
          placeholder="Pick a Date Range"
          popupPosition="bottom left"
          className="device-usage-range-picker"
          name="datesRange"
          closable
          hideMobileKeyboard
          clearable
          animation="none"
          iconPosition="left"
          autoComplete="off"
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onClear={this.onClear}
        />
      </div>
    );
  }
}

export default ReportRangeSelector;
