const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_API_PATH,
  REACT_APP_CDN_PATH,
  REACT_APP_PANEL_PATH,
  REACT_APP_API_VERSION,
  OMMA_HQ_RESELLER_ID,
} = process.env;

const config = {
  ENVIRONMENT: REACT_APP_ENVIRONMENT || 'dev',
  API_PATH: REACT_APP_API_PATH || 'https://api.omma.dev',
  CDN_PATH: REACT_APP_CDN_PATH || 'https://static.omma.dev',
  PANEL_PATH: REACT_APP_PANEL_PATH || 'https://panel.omma.dev',
  API_VERSION: REACT_APP_API_VERSION || 'bo_v1',
  OMMA_HQ_RESELLER_ID: OMMA_HQ_RESELLER_ID || '2',
};

export default config;
