import {Form} from 'semantic-ui-react';
import {InputField} from 'react-semantic-redux-form';
import React from 'react';
import {Field, reduxForm} from 'redux-form';

import {parseNumber, formatNumber} from 'libphonenumber-js';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import RoleSelectorFormInput from '../../../components/roleselector/form';
import TwoFactorSelectorFormInput from '../../../components/twofactorselector/form';
import 'react-phone-number-input/style.css';

const PhoneNumberInput = ({label, required, input, meta}) => (
  <Form.Field required={required}>
    <label>{label}</label>
    <PhoneInput defaultCountry="TR" value={input.value} onChange={input.onChange} placeholder="0501 234 56 78" />
    {meta.touched && meta.error && <span style={{color: 'red'}}>{meta.error}</span>}
    {input.value && !isValidPhoneNumber(input.value) && <span style={{color: 'red'}}>Invalid phone number</span>}
  </Form.Field>
);

const AccountAddNewUser = (props) => {
  const {handleSubmit, invalid, submitting} = props;
  return (
    <Form onSubmit={handleSubmit}>
      <Field component={InputField} name="name" placeholder="Name" label="Name" required />
      <Field component={InputField} name="email" placeholder="Email" label="Email" required />
      <Field component={PhoneNumberInput} name="phoneNumber" placeholder="Phone Number" label="Phone Number" required />
      <Field component={InputField} name="password" placeholder="Password" label="Password" required />
      <Field component={RoleSelectorFormInput} name="roleId" label="Role" required />
      <Field component={TwoFactorSelectorFormInput} name="twofactor" label="2FA" required />

      <Form.Button type="submit" disabled={invalid || submitting}>
        Create
      </Form.Button>
    </Form>
  );
};

const validator = (values) => {
  let formated = '';
  try {
    formated = formatNumber(parseNumber(values.phoneNumber), 'E.164');
  } catch (err) {
    /* NOOP */
  }

  return {
    name: !values.name && 'Required',
    email:
      (!values.email && 'Required') ||
      (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && 'Invalid mail'),
    phoneNumber: (!values.phoneNumber && 'Required') || (!formated && 'Invalid phone number'),
    password: !values.password && 'Required',
    roleId: !values.roleId && 'Required',
  };
};

export default reduxForm({
  form: 'AccountAddNewUser_Form',
  enableReinitialize: true,
  validate: validator,
})(AccountAddNewUser);
