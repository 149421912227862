import {Form} from 'semantic-ui-react';
import React from 'react';
import {Field, reduxForm} from 'redux-form';
import RoleSelectorFormInput from '../../../components/roleselector/form';
import UserSelectorFormInput from '../../../components/userselector/form';

const AccountAddNewUser = (props) => {
  const {handleSubmit, pristine, submitting} = props;
  return (
    <Form onSubmit={handleSubmit}>
      <Field component={UserSelectorFormInput} name="userId" placeholder="User" label="User" required />
      <Field component={RoleSelectorFormInput} name="roleId" label="Role" required />

      <Form.Button type="submit" disabled={pristine || submitting}>
        Create
      </Form.Button>
    </Form>
  );
};

export default reduxForm({
  form: 'AccountAddNewUser_Form',
  enableReinitialize: true,
})(AccountAddNewUser);
