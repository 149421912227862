import _ from 'lodash';
import React, {Component} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';
import API from '../../lib/api';

export default class AccountSelectorFormInput extends Component {
  state = {
    accounts: [],
    blockUI: false,
  };

  constructor(props) {
    super(props);
    this.onSearchChangedDebounced = _.debounce(this.onSearchChange.bind(this), 500);
  }

  fetch() {
    const {includeAllOption} = this.props;
    return new Promise((resolve, reject) => {
      this.setState({blockUI: true}, () => {
        API.getInstance()
          .get('/account', {
            params: {name: this.state.name, limit: this.props.limit || 10},
          })
          .then((response) => {
            const accounts = _.map(response.data.rows, (account) => ({
              key: account.id,
              value: account.id,
              text: account.name,
            }));

            if (includeAllOption) accounts.unshift({key: '*', value: '*', text: 'ALL'});

            this.setState({accounts, blockUI: false});
            resolve();
          })
          .catch(reject);
      });
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.fetch();
  }

  onSearchChange(query) {
    this.setState({name: query}, () => {
      this.fetch();
    });
  }

  render() {
    const {required, label, onChange, key, name, value, input} = this.props;

    const intValue = parseInt(value, 10);

    const {blockUI, accounts} = this.state;

    return (
      <Form.Field required={required}>
        <label>{label}</label>
        <Dropdown
          key={key}
          name={name}
          placeholder={label}
          search
          selection
          options={accounts}
          disabled={blockUI}
          onChange={input ? (e, {value: formValue}) => input.onChange(formValue) : onChange}
          value={input ? input.value : intValue || value}
          onSearchChange={(e, data) => this.onSearchChangedDebounced(data.searchQuery)}
        />
      </Form.Field>
    );
  }
}
