import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import ReactJson from 'react-json-view';
import fileDownload from 'js-file-download';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import {Table, Button, Icon, Modal} from 'semantic-ui-react';

import API from '../../lib/api';

import FilteredTable from '../../components/table/filtered-table';
import UserSelectorFormInput from '../../components/userselector/form';
import AccountSelectorFormInput from '../../components/accountselector/form';
import ReportRangeSelector from '../device-usage/list/range';

import {PERMISSIONS} from '../../constants/permissions';

class Audit extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  timeFormat = 'DD.MM.YYYY';

  state = {
    userId: null,
    permissionName: null,
    auditDetailModalOpen: false,
    generatingReport: false,
    generatingReportError: null,
    generatingReportSuccess: null,
    accountId: null,
    rangeBoundaries: '',
  };

  constructor(props) {
    super(props);
    const searchParams = queryString.parse(props.location.search);
    this.state.rangeBoundaries =
      searchParams.dateRange ||
      `${moment().subtract(1, 'months').format(this.timeFormat)}-${moment().format(this.timeFormat)}`;
  }

  renderRow(item) {
    return (
      <Table.Row key={item.id}>
        <Table.Cell>{item.uuid}</Table.Cell>
        <Table.Cell>{item.regToken}</Table.Cell>
        <Table.Cell>{item.adoptedAt ? moment(item.adoptedAt).calendar() : 'Not Adopted'}</Table.Cell>
        <Table.Cell>{item.adoption ? item.adoption.WEB_API_ROOT : '-'}</Table.Cell>
        <Table.Cell>{moment(item.updatedAt).calendar()}</Table.Cell>
        <Table.Cell>{moment(item.createdAt).calendar()}</Table.Cell>
      </Table.Row>
    );
  }

  onAuditRecordDetail(item, event) {
    this.setState({
      auditDetailModalOpen: true,
      auditDetailData: item,
    });
  }

  onTableReady = ({searchParams}) => {
    const {userId, permissionName, accountId} = searchParams;
    this.setState({userId, permissionName, accountId});
  };

  onRangeChange({boundaries}) {
    const rangeBoundaries = boundaries.replace(/ /g, '');

    if (isEqual(rangeBoundaries, this.state.rangeBoundaries)) return;

    this.setState({rangeBoundaries});
  }

  renderAuditResults() {
    const {userId, rangeBoundaries} = this.state;

    const {history, match, location} = this.props;

    return (
      <div style={{marginTop: '30px', marginBottom: '30px'}}>
        <FilteredTable
          history={history}
          match={match}
          location={location}
          ref={(ref) => {
            this.apiTableRef = ref;
          }}
          key="audit_results_list"
          apiPath={`/audit/user/${userId || '*'}`}
          searchRoot="/audit"
          onReady={this.onTableReady}
          extraSearchParams={{dateRange: rangeBoundaries}}
          fields={[
            {
              component: AccountSelectorFormInput,
              name: 'accountId',
              label: 'Account Name',
              onChange: (accountId) => this.setState({accountId}),
              includeAllOption: true,
            },
            {
              component: UserSelectorFormInput,
              filteringId: this.state.accountId,
              name: 'userId',
              label: 'User ID',
              onChange: (formUserId) => this.setState({userId: formUserId}),
              includeAllOption: true,
            },
            {
              component: 'dropdown',
              name: 'permissionName',
              label: 'Permission Name',
              defaultValue: '*',
              props: {options: PERMISSIONS, selection: true, search: true},
              onChange: (permissionName) => this.setState({permissionName}),
            },
            {
              name: 'dateRange',
              label: 'Date Range',
              component: ReportRangeSelector,
              defaultValue: this.state.rangeBoundaries,
              props: {
                initialValue: rangeBoundaries.replace('-', ' - '),
                onRangeChange: (data) => this.onRangeChange(data),
              },
            },
          ]}
          headers={[
            {key: 'timestamp', displayName: 'Date', sortable: false},
            {key: 'email', displayName: 'User E-Mail', sortable: false},
            {key: 'permissionName', displayName: 'Type', sortable: false},
            {key: 'url', displayName: 'Url', sortable: false},
            {key: 'ip', displayName: 'IP', sortable: false},
            {key: 'sessionId', displayName: 'SessionId', sortable: false},
            {key: 'accountId', displayName: 'AccountId', sortable: false},
            {key: 'actions', displayName: '', sortable: false},
          ]}
          renderRow={(item) => this.renderAuditResult(item)}
        />
        {this.renderAuditDetail()}
      </div>
    );
  }

  renderAuditResult(item) {
    return (
      <Table.Row key={`audit_record_${item.timestamp}_${item.url}`}>
        <Table.Cell className="small-field">{moment(item.timestamp).format('MMMM Do YYYY, h:mm:ss a')}</Table.Cell>
        <Table.Cell className="small-field">{item.email}</Table.Cell>
        <Table.Cell className="small-field">{item.permissionName}</Table.Cell>
        <Table.Cell className="url-field">
          <span className="url-method">{`${item.method}`}</span> {`${item.url}`}
          <span className="url-extra">{`(${item.statusCode} in ${item.responseTime}ms)`}</span>
        </Table.Cell>
        <Table.Cell className="small-field">{item.ip}</Table.Cell>
        <Table.Cell className="small-field">{item.sessionId}</Table.Cell>
        <Table.Cell className="small-field">{item.accountId}</Table.Cell>
        <Table.Cell>
          <Button icon color="blue" onClick={(event) => this.onAuditRecordDetail(item, event)}>
            <Icon name="magnify" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }

  renderAuditDetail() {
    const {auditDetailData} = this.state;

    let content = <Modal.Content image />;

    if (auditDetailData) {
      content = (
        <Modal.Content image>
          <h3>Record Detail</h3>
          <ReactJson
            src={auditDetailData}
            indentWidth={2}
            collapsed
            collapseStringsAfterLength={255}
            style={{marginBottom: '30px'}}
          />
          <Button icon color="blue" onClick={(event) => this.setState({auditDetailModalOpen: false})}>
            Done
          </Button>
        </Modal.Content>
      );
    }

    return (
      <Modal open={this.state.auditDetailModalOpen} closeOnDimmerClick closeOnDocumentClick>
        <Modal.Content image>{content}</Modal.Content>
      </Modal>
    );
  }

  renderDownloadButton() {
    const {userId, permissionName, rangeBoundaries, generatingReport, generatingReportError, generatingReportSuccess} =
      this.state;

    if (!userId) return null;

    return (
      <div>
        <Button
          disabled={generatingReport}
          loading={generatingReport}
          icon
          style={{marginBottom: '30px'}}
          onClick={() => {
            this.setState({
              generatingReport: true,
              generatingReportError: null,
              generatingReportSuccess: false,
            });

            const params = {timeout: 0, dateRange: rangeBoundaries};

            if (permissionName && permissionName !== '*') {
              params.permissionName = permissionName;
            }

            API.getInstance()
              .get(`/audit/user/${userId}`, {params})
              .then((response) => {
                const {rows} = response.data;
                fileDownload(JSON.stringify(rows, null, 2), `${userId}_${permissionName || 'all'}_report.json`);

                this.setState({
                  generatingReport: false,
                  generatingReportError: null,
                  generatingReportSuccess: true,
                });
              })
              .catch((err) => {
                this.setState({
                  generatingReport: false,
                  generatingReportError: `Could not generate report: ${err.message ? err.message : err}`,
                  generatingReportSuccess: false,
                });
              });
          }}
        >
          <Icon name="download" /> Download
        </Button>
        {generatingReportError ? <div>{generatingReportError}</div> : null}
        {generatingReportSuccess ? <div>Report generated successfully</div> : null}
      </div>
    );
  }

  render() {
    return (
      <div className="view">
        <ul className="post-bar">
          <h1>Audit</h1>
        </ul>
        {this.renderAuditResults()}
        {this.renderDownloadButton()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
