import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import React, {Component} from 'react';
import moment from 'moment';
import {Table, Icon} from 'semantic-ui-react';
import queryString from 'query-string';
import {toast} from 'react-semantic-toasts';

import DetailView from './detail';
import ReportRangeSelector from './range';
import ReportSummary from './summary';
import FilteredTable from '../../../components/table/filtered-table';

import './index.css';

const ACCOUNT_STATUSES = [
  {key: 'active', value: 'active', text: 'Active'},
  {key: 'suspended', value: 'suspended', text: 'Suspended'},
  {key: 'deactivated', value: 'deactivated', text: 'Deactivated'},
];

const DEFAULT_SEARCH_PARAMS = {
  page: 1,
  name: '',
  status: ['active', 'suspended'],
  sortBy: 'name',
};

class DeviceUsage extends Component {
  state = {
    ranges: [],
    rangeBoundaries: '',
    view: 'list',
    activeAccount: null,
  };

  constructor(props) {
    super(props);

    const searchParams = queryString.parse(props.location.search);
    this.state.rangeBoundaries = searchParams.daterange || '';

    this.filteredTable = React.createRef();

    this.resetRowData = this.resetRowData.bind(this);
    this.resetRowData();
  }

  navigate(updated) {
    // This is an antipattern.
    const filteredTable = this.filteredTable.current;
    if (filteredTable) filteredTable.navigate(updated);
  }

  onRangeChange({boundaries, ranges}) {
    if (isEqual(ranges, this.state.ranges)) return;

    this.resetRowData();

    const rangeBoundaries = boundaries.replace(/ /g, '');

    this.setState({ranges, rangeBoundaries});
    this.navigate({daterange: rangeBoundaries});
  }

  setRowData(id, range, data) {
    this.rowData[`${id}_${range.start}_${range.end}`] = data;
  }

  resetRowData() {
    this.rowData = {};
  }

  getHeaders() {
    const {ranges} = this.state;

    const headers = [{key: 'name', displayName: 'Account Name', sortable: true, style: {width: '288px'}}];

    ranges.forEach((range, index) => {
      const today = moment();
      const start = moment(range.start, 'DD.MM.YYYY');
      const end = moment(range.end, 'DD.MM.YYYY');

      let displayName = `${range.start} - ${range.end}`;

      if (start.month() === end.month() && start.year() === end.year()) {
        displayName = `${start.date()}-${end.date()} ${start.format('MMM')}`;

        if (start.year() !== today.year()) {
          displayName += ` (${start.year()})`;
        }
      }

      headers.push({
        style: {textAlign: 'center'},
        key: range.start + range.end,
        displayName,
      });
    });

    headers.push({
      style: {width: '60px'},
    });

    return headers;
  }

  onRowClicked(item) {
    const {rowData} = this;
    const {ranges} = this.state;

    const rowDataLoaded = ranges.every((range) => rowData[`${item.id}_${range.start}_${range.end}`]);

    if (!rowDataLoaded) {
      return toast({
        type: 'info',
        icon: 'download',
        title: 'Still Loading Data...',
        description: 'Please wait until row completely loaded.',
      });
    }

    this.setState({view: 'detail', activeAccount: item});
  }

  renderRow(item) {
    const {ranges} = this.state;

    return (
      <Table.Row key={item.id} onClick={(event) => this.onRowClicked(item, event)}>
        <Table.Cell style={{height: '67px'}}>
          <div>{item.name}</div>
        </Table.Cell>

        {ranges.map((range, index) => (
          <Table.Cell key={`${item.id}#${range.start}_${range.end}`}>
            <ReportSummary
              data={this.rowData[`${item.id}_${range.start}_${range.end}`]}
              accountId={item.id}
              dateRange={range}
              onRowDataLoad={(data) => this.setRowData(item.id, range, data)}
            />
          </Table.Cell>
        ))}

        <Table.Cell style={{textAlign: 'center', cursor: 'pointer'}}>
          <Icon name="chart pie" size="large" color="blue" />
        </Table.Cell>
      </Table.Row>
    );
  }

  render() {
    const {history, match, location} = this.props;
    const {rangeBoundaries, ranges, view, activeAccount} = this.state;

    if (view === 'detail') {
      return (
        <DetailView
          account={activeAccount}
          data={pickBy(this.rowData, (v, k) => k.startsWith(`${activeAccount.id}_`))}
          ranges={ranges}
          headers={this.getHeaders()}
          onBack={() => this.setState({view: 'list', activeAccount: null})}
        />
      );
    }

    return (
      <div className="view">
        <div className="post-bar">
          <h1>Device Usage</h1>
        </div>
        <FilteredTable
          ref={this.filteredTable}
          extraSearchParams={{daterange: rangeBoundaries}}
          updateOnPropChange
          history={history}
          match={match}
          location={location}
          searchRoot="/device-usage"
          apiPath="/account"
          defaultSort="name"
          itemsPerPage={8}
          fields={[
            {
              name: 'date-range',
              component: ReportRangeSelector,
              props: {
                initialValue: rangeBoundaries.replace('-', ' - '),
                onRangeChange: (data) => this.onRangeChange(data),
              },
            },
            {
              component: 'input',
              name: 'name',
              label: 'Account Name',
              defaultValue: DEFAULT_SEARCH_PARAMS.name,
            },
            {
              component: 'dropdown',
              name: 'status',
              label: 'Status',
              defaultValue: DEFAULT_SEARCH_PARAMS.status,
              parse: (status) => {
                const parsed = status
                  .split(',')
                  .map((s) => s.trim())
                  .filter((s) => !!s);
                return parsed.length > 0 ? parsed : DEFAULT_SEARCH_PARAMS.status;
              },
              props: {selection: true, multiple: true, options: ACCOUNT_STATUSES},
            },
          ]}
          headers={this.getHeaders()}
          renderRow={(item) => this.renderRow(item)}
          onFilterFormSubmit={() => this.resetRowData()}
        />
      </div>
    );
  }
}

export default DeviceUsage;
