import React, {Component} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';

export default class AccountBusinessLineSelector extends Component {
  state = {
    items: [
      {key: 'signage', value: 'signage', text: 'Signage'},
      {key: 'vq', value: 'vq', text: 'VQ'},
    ],
  };

  render() {
    const {required, label, input, disabled} = this.props;

    const {items} = this.state;

    return (
      <Form.Field required={required}>
        <label>{label}</label>
        <Dropdown
          disabled={disabled}
          value={input.value}
          placeholder={label}
          search
          selection
          options={items}
          onChange={(e, {value}) => input.onChange(value)}
        />
      </Form.Field>
    );
  }
}
