import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Confirm} from 'semantic-ui-react';
import API from '../../lib/api';

import {options} from './form';

class TwoFactorSelector extends Component {
  static propTypes = {
    value: PropTypes.string,
    userId: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    onTwoFactorChanged: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      confirmationContent: '',
      showConfirmation: false,
      nextValue: null,
      value: props.value,
      blockUI: false,
    };
  }

  update(twofactor) {
    const {userId} = this.props;

    return new Promise((resolve, reject) => {
      this.setState({blockUI: true}, () => {
        API.getInstance()
          .put(`/user/${userId}`, {twofactor})
          .then((response) => {
            this.setState({blockUI: false});
            if (this.props.onTwoFactorChanged) {
              this.props.onTwoFactorChanged();
            }
            resolve();
          })
          .catch(reject);
      });
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEqual(nextProps.value, this.state.value)) return;

    this.setState({value: nextProps.value});
  }

  onChanged(value) {
    if (value === this.state.value) return;

    this.setState((prevState) => ({
      showConfirmation: true,
      nextValue: value,
      confirmationContent: `Are you sure you want to change ${this.props.userName}'s
        2FA method from "${prevState.value}" to "${value}"?`,
    }));
  }

  confirmationCanceled() {
    this.setState({showConfirmation: false});
  }

  confirmationApproved() {
    const {nextValue} = this.state;
    this.update(nextValue)
      .then(() => {
        this.setState({
          showConfirmation: false,
          value: nextValue,
          nextValue: null,
        });
      })
      .catch(() => {
        this.setState({
          showConfirmation: false,
          nextValue: null,
        });
      });
  }

  render() {
    const {blockUI = false, showConfirmation, value, confirmationContent} = this.state;

    return (
      <div>
        <Dropdown
          value={value}
          placeholder="2FA"
          search
          selection
          options={options}
          onChange={(e, {value: formValue}) => this.onChanged(formValue)}
          disabled={blockUI}
        />
        <Confirm
          open={showConfirmation}
          content={confirmationContent}
          onCancel={() => this.confirmationCanceled()}
          onConfirm={() => this.confirmationApproved()}
        />
      </div>
    );
  }
}

export default TwoFactorSelector;
