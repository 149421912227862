import * as roleTypes from '../constants/role';
import API from '../lib/api';
import {handleAPIError} from '../lib/utils';

export function getAll() {
  return (dispatch) =>
    API.getInstance()
      .get(`/role`)
      .then((response) => {
        dispatch({
          type: roleTypes.ROLES_RECEIVED,
          payload: response.data,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function clear() {
  return (dispatch) => {
    dispatch({
      type: roleTypes.ROLES_CLEARED,
    });
  };
}
