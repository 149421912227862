import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Sidebar, Menu, Icon} from 'semantic-ui-react';
import logo from '../../images/logo-white.png';

import './style.css';

export default class CustomSidebar extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    authenticationActions: PropTypes.object.isRequired,
  };

  state = {
    activeItem: '/',
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {history} = this.props;
    this.setState({activeItem: history.location.pathname});
  }

  handleItemClick(e, {name}) {
    this.setState({activeItem: name});
    this.props.history.push(name);
  }

  logout() {
    const {authenticationActions, history} = this.props;
    authenticationActions.logout();
    history.push('/login');
  }

  render() {
    const {activeItem} = this.state;

    return (
      <Sidebar as={Menu} visible width="thin" icon="labeled" vertical inverted>
        <img src={logo} className="App-logo" alt="OMMA Logo" />
        <Menu.Item name="/" onClick={(i, s) => this.handleItemClick(i, s)} active={activeItem === '/'}>
          <Icon name="home" />
          Homepage
        </Menu.Item>
        <Menu.Item name="/accounts" onClick={(i, s) => this.handleItemClick(i, s)} active={activeItem === '/accounts'}>
          <Icon name="users" />
          Accounts
        </Menu.Item>
        <Menu.Item name="/users" onClick={(i, s) => this.handleItemClick(i, s)} active={activeItem === '/users'}>
          <Icon name="user" />
          Users
        </Menu.Item>
        <Menu.Item name="/hello" onClick={(i, s) => this.handleItemClick(i, s)} active={activeItem === '/hello'}>
          <Icon name="child" />
          Hello Devices
        </Menu.Item>
        <Menu.Item name="/audit" onClick={(i, s) => this.handleItemClick(i, s)} active={activeItem === '/audit'}>
          <Icon name="magnify" />
          Audit
        </Menu.Item>
        <Menu.Item
          name="/device-usage"
          onClick={(i, s) => this.handleItemClick(i, s)}
          active={activeItem === '/device-usage'}
        >
          <Icon name="chart bar" />
          Device Usage
        </Menu.Item>
        <Menu.Item
          name="/addon-usage"
          onClick={(i, s) => this.handleItemClick(i, s)}
          active={activeItem === '/addon-usage'}
        >
          <Icon name="plug" />
          Addon Usage
        </Menu.Item>
        <Menu.Item
          name="/addon-widget"
          onClick={(i, s) => this.handleItemClick(i, s)}
          active={activeItem === '/addon-widget'}
        >
          <Icon name="puzzle" size="mini" />
          Addon & Widget
        </Menu.Item>
        <Menu.Item onClick={() => this.logout()} className="logout-button">
          Logout
        </Menu.Item>
      </Sidebar>
    );
  }
}
