import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Table} from 'semantic-ui-react';
import moment from 'moment';
import {connect} from 'react-redux';

import FilteredTable from '../../../components/table/filtered-table';

class HelloList extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  renderRow(item) {
    return (
      <Table.Row key={item.uuid}>
        <Table.Cell>{item.uuid}</Table.Cell>
        <Table.Cell>{item.regToken}</Table.Cell>
        <Table.Cell>{item.adoptedAt ? moment(item.adoptedAt).calendar() : 'Not Adopted'}</Table.Cell>
        <Table.Cell>{item.adoption ? item.adoption.WEB_API_ROOT : '-'}</Table.Cell>
        <Table.Cell>{moment(item.updatedAt).calendar()}</Table.Cell>
        <Table.Cell>{moment(item.createdAt).calendar()}</Table.Cell>
      </Table.Row>
    );
  }

  render() {
    const {history, match, location} = this.props;
    return (
      <div className="view">
        <ul className="post-bar">
          <h1>Hello Devices</h1>
        </ul>
        <FilteredTable
          history={history}
          match={match}
          location={location}
          defaultSort="-adoptedAt"
          apiPath="/device/hello"
          searchRoot="/hello"
          fields={[{component: 'input', name: 'uuid', label: 'UUID'}]}
          headers={[
            {key: 'uuid', displayName: 'UUID', sortable: true},
            {key: 'regToken', displayName: 'Registration Token', sortable: false},
            {key: 'adoptedAt', displayName: 'Adoption', sortable: true},
            {key: 'ENV', displayName: 'Env', sortable: false},
            {key: 'updatedAt', displayName: 'UpdatedAt', sortable: true},
            {key: 'createdAt', displayName: 'CreatedAt', sortable: true},
          ]}
          renderRow={(item) => this.renderRow(item)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HelloList);
