import moment from 'moment';
import values from 'lodash/values';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import React, {Component} from 'react';
import {Icon, Popup} from 'semantic-ui-react';

import Pagination from '../../../components/pagination';

const ITEM_PER_PAGE = 10;

class Detail extends Component {
  state = {
    filteredDevices: [],
    page: 1,
  };

  constructor(props) {
    super(props);

    this.devices = [];
  }

  componentDidMount() {
    const {data} = this.props;

    // Get all distinct devices from data.
    let devices;
    devices = values(data)
      .map((v) => v.rows)
      .flat()
      .map((v) => v.Device); // get all devices
    devices = uniqBy(devices, 'id'); // uniqify devices

    const devicesWithName = devices.filter((device) => device.name);
    const devicesWithoutName = devices.filter((device) => !device.name);

    devices = [].concat(
      orderBy(devicesWithName, [({name}) => name.toLowerCase()], ['asc']),
      orderBy(devicesWithoutName, [({id}) => id], ['asc']),
    );

    this.devices = devices;

    this.changePage(1);
  }

  changePage(page) {
    const {ranges} = this.props;

    const pageStart = (page - 1) * ITEM_PER_PAGE;

    const filteredDevices = this.devices.slice(pageStart, pageStart + ITEM_PER_PAGE).map((device) => ({
      ...device,
      ranges: ranges.map((range) => ({
        range,
        graphs: this.calculate(range, device),
      })),
    }));

    this.setState({page, filteredDevices});
  }

  calculate(range, device) {
    const {data, account} = this.props;

    let registrations = data[`${account.id}_${range.start}_${range.end}`].rows.filter((r) => r.Device.id === device.id);

    registrations = orderBy(registrations, 'adoptedAt');

    const startDate = moment(range.start, 'DD.MM.YYYY');
    const endDate = moment(range.end, 'DD.MM.YYYY');
    const durationOfRange = endDate.diff(startDate, 'days') + 1;

    return registrations.map((registration) => {
      const adoptedAt = moment(registration.adoptedAt);
      const dismissedAt = moment(registration.dismissedAt);

      let xStart = 0;
      let xEnd = 1;

      if (adoptedAt.isAfter(startDate, 'days')) {
        xStart = adoptedAt.diff(startDate, 'days') / durationOfRange;
      }

      if (registration.dismissedAt && dismissedAt.isBefore(endDate, 'days')) {
        xEnd = dismissedAt.diff(startDate, 'days') / durationOfRange;
      }

      const drawStartMarker = adoptedAt.isBetween(startDate, endDate, 'days', '[]');
      const drawEndMarker = registration.dismissedAt && dismissedAt.isBetween(startDate, endDate, 'days', '[]');

      let graphRange;

      if (!registration.dismissedAt) {
        graphRange = `${adoptedAt.format('DD.MM.YYYY HH:mm')} - Present`;
      } else if (adoptedAt.isSame(dismissedAt, 'day')) {
        graphRange = `[${adoptedAt.format('DD.MM.YYYY')}]  ${adoptedAt.format('HH:mm')} - ${dismissedAt.format(
          'HH:mm',
        )}`;
      } else if (adoptedAt.isSame(dismissedAt, 'month')) {
        graphRange = `[${adoptedAt.format('MM.YYYY')}]  ${adoptedAt.format('DD[th] HH:mm')} - ${dismissedAt.format(
          'DD[th] HH:mm',
        )}`;
      } else if (adoptedAt.isSame(dismissedAt, 'year')) {
        graphRange = `[${adoptedAt.format('YYYY')}]  ${adoptedAt.format('DD.MM HH:mm')} - ${dismissedAt.format(
          'DD.MM HH:mm',
        )}`;
      } else {
        graphRange = `${adoptedAt.format('DD.MM.YYYY HH:mm')} - ${dismissedAt.format('DD.MM.YYYY HH:mm')}`;
      }

      return {
        graphRange,
        range,
        registration,
        adoptedAt,
        dismissedAt,
        xStart,
        xEnd,
        drawStartMarker,
        drawEndMarker,
      };
    });
  }

  render() {
    const {filteredDevices, page} = this.state;
    const {onBack, headers, account} = this.props;

    return (
      <div className="device-usage-detail">
        <div className="device-usage-detail-header">
          <div className="back-button" onClick={onBack}>
            <Icon name="angle left" /> Back
          </div>
          <h1>{account.name}</h1>
        </div>
        <table className="device-usage-detail-table ui celled selectable sortable table">
          <thead>
            <tr>
              <th className="table-info" colSpan="8">
                <div className="table-info-content">
                  <div>Device Usage Graph</div>
                  <div>
                    <Pagination
                      currentPage={page}
                      totalItems={this.devices.length}
                      onClick={(p) => this.changePage(p)}
                      itemsPerPage={ITEM_PER_PAGE}
                      maxDisplayedPageCount={5}
                    />
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th>Device Id</th>
              <th>Device Name</th>
              {headers.slice(1, -1).map((range) => (
                <th key={range.key} className="date-range">
                  {range.displayName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredDevices.map((device) => (
              <tr className="detail-rows" key={device.uuid}>
                <td>{device.id}</td>
                <td>{device.name || '[Deleted]'}</td>
                {device.ranges.map(({range, graphs}) => (
                  <td key={`${range.start}_${range.end}`} className="graph-container">
                    {graphs.map((graph) => (
                      <div key={graph.registration.adoptedAt}>
                        <Popup
                          trigger={
                            <div
                              className="graph"
                              style={{
                                width: `${(graph.xEnd - graph.xStart) * 100}%`,
                                left: `${graph.xStart * 100}%`,
                              }}
                            />
                          }
                          content={graph.graphRange}
                          position="top center"
                          size="small"
                          inverted
                        />
                        {graph.drawStartMarker && (
                          <div className="graph-marker-start" style={{left: `${graph.xStart * 100}%`}} />
                        )}
                        {graph.drawEndMarker && (
                          <div className="graph-marker-end" style={{left: `${graph.xEnd * 100}%`}} />
                        )}
                      </div>
                    ))}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Detail;
