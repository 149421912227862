import React, {Component} from 'react';
import {Button, Modal, Label, Input} from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class SmsVerificationModal extends Component {
  state = {
    validationStatus: null,
    helpMessage: null,
    token: '',
  };

  static propTypes = {
    authenticationActions: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  onCodeChange(e) {
    const token = e.target.value;
    const newState = {token};

    if (token.length > 6) {
      newState.validationStatus = 'error';
      newState.helpMessage = 'SMS token must be shorter than 6 characters.';
    } else {
      newState.validationStatus = null;
      newState.helpMessage = null;
    }

    this.setState(newState);
  }

  onValidate() {
    const {authenticationActions} = this.props;
    const {token} = this.state;

    authenticationActions.sendLoginVerificationToken(token).catch((err) => {
      const {status} = err.response;
      const helpMessage = status === 401 ? 'Invalid sms code.' : 'SMS verification failed.';

      this.setState({
        validationStatus: 'error',
        helpMessage,
      });
    });
  }

  renderHelpMessage() {
    const {validationStatus, helpMessage} = this.state;

    switch (validationStatus) {
      case 'error':
        return (
          <Label basic color="red" pointing>
            {helpMessage}
          </Label>
        );
      case 'success':
        return null;
      case 'warning':
        return (
          <Label basic color="orange" pointing>
            {helpMessage}
          </Label>
        );
      default:
        return null;
    }
  }

  render() {
    const {isActive, onClose} = this.props;
    const {token} = this.state;

    return (
      <Modal size="tiny" open={isActive} onClose={onClose}>
        <Modal.Header>SMS Verification</Modal.Header>
        <Modal.Content>
          <Input
            type="text"
            placeholder="SMS Token"
            style={{width: '100%'}}
            value={token}
            onChange={(value) => this.onCodeChange(value)}
          />
          {this.renderHelpMessage()}
        </Modal.Content>
        <Modal.Actions>
          <Button positive icon="checkmark" labelPosition="right" content="Submit" onClick={() => this.onValidate()} />
        </Modal.Actions>
      </Modal>
    );
  }
}
