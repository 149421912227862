import findIndex from 'lodash/findIndex';
import * as accountTypes from '../constants/account';

const initialState = () => ({});

export default function accountReducer(state = initialState(), action = {}) {
  switch (action.type) {
    case accountTypes.ACCOUNT_RECEIVED:
      return {...state, [action.payload.id]: action.payload};
    case accountTypes.ACCOUNT_REMOVED:
      return {...state, [action.payload.id]: undefined};
    case accountTypes.IMPORT_JOBS_LOADED:
      return {...state, importJobs: action.payload};
    case accountTypes.IMPORT_JOBS_ADDED:
      return {...state, importJobs: [action.payload, ...state.importJobs]};
    case accountTypes.IMPORT_JOBS_UPDATED: {
      const importJobs = [].concat(state.importJobs);
      const jobIndex = findIndex(state.importJobs, {jobId: action.payload.jobId});
      if (jobIndex > -1) {
        importJobs[jobIndex] = action.payload;
      } else {
        importJobs.push(action.payload);
      }
      return {...state, importJobs};
    }
    case accountTypes.VQ_IMPORT_JOBS_LOADED:
      return {...state, vqImportJobs: action.payload};
    case accountTypes.VQ_IMPORT_JOBS_ADDED:
      return {...state, vqImportJobs: [action.payload, ...state.vqImportJobs]};
    case accountTypes.VQ_IMPORT_JOBS_UPDATED: {
      const vqImportJobs = [].concat(state.vqImportJobs);
      const jobIndex = findIndex(state.vqImportJobs, {jobId: action.payload.jobId});
      if (jobIndex > -1) {
        vqImportJobs[jobIndex] = action.payload;
      } else {
        vqImportJobs.push(action.payload);
      }
      return {...state, vqImportJobs};
    }
    default:
      return state;
  }
}
