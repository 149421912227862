/* eslint-disable max-classes-per-file */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Table, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';

import FilteredTable from '../../components/table/filtered-table';
import API from '../../lib/api';

import './style.css';

class AccountSelector extends Component {
  state = {
    accountList: [],
    blockUi: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    const endpoint = '/addon-usage/accounts_that_have_addon';
    const params = {};

    return new Promise((resolve, reject) => {
      this.setState({blockUi: true}, () => {
        API.getInstance()
          .get(endpoint, params)
          .then((response) => {
            const accountList = response.data.sort((a, b) => a.name.localeCompare(b.name));

            this.setState({
              accountList,
              blockUi: false,
            });

            resolve();
          })
          .catch(reject);
      });
    });
  }

  render() {
    const {name, label, value, onChange} = this.props;
    const {blockUi, accountList} = this.state;

    ///

    const options = accountList.map((account) => ({
      key: account.slug,
      text: account.name,
      value: account.id,
    }));

    ///

    let cleanValue = value;

    if (typeof value === 'string') {
      cleanValue = value.split(',').map((val) => parseInt(val, 10));
    }

    if (!cleanValue) {
      cleanValue = [];
    }

    ///

    return (
      <div className="dropdown-container">
        <Dropdown
          placeholder={label}
          name={name}
          value={cleanValue}
          fluid
          multiple
          selection
          search
          disabled={blockUi}
          options={options}
          onChange={onChange}
        />
      </div>
    );
  }
}

function SummaryTable({data}) {
  const summaryByAddon = {};

  data.forEach((row) => {
    if (!summaryByAddon.hasOwnProperty(row.addonKey)) {
      summaryByAddon[row.addonKey] = 0;
    }

    summaryByAddon[row.addonKey] += row.deviceCount;
  });

  const summaryByAddonRows = Object.keys(summaryByAddon).map((addonKey) => (
    <Table.Row key={`summary-by-addon-${addonKey}`}>
      <Table.Cell>{addonKey}</Table.Cell>
      <Table.Cell>{summaryByAddon[addonKey]}</Table.Cell>
    </Table.Row>
  ));

  return (
    <div className="summary-block">
      <h3>Total By Addons</h3>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Addon Key</Table.HeaderCell>
            <Table.HeaderCell>Device Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{summaryByAddonRows}</Table.Body>
      </Table>
    </div>
  );
}

class AddonUsage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    tableData: [],
  };

  renderRow(item) {
    return (
      <Table.Row key={item.accountName + item.addonKey}>
        <Table.Cell>{item.accountName}</Table.Cell>
        <Table.Cell>{item.addonKey}</Table.Cell>
        <Table.Cell>{item.deviceCount}</Table.Cell>
      </Table.Row>
    );
  }

  onTableData = (tableData) => {
    this.setState({tableData});
  };

  render() {
    const {history, match, location} = this.props;

    return (
      <div className="view">
        <ul className="post-bar">
          <h1>Addon Usage</h1>
        </ul>

        <FilteredTable
          history={history}
          match={match}
          location={location}
          defaultSort="accountName"
          apiPath="/addon-usage"
          searchRoot="/addon-usage"
          fields={[{component: AccountSelector, name: 'account_id_list', label: 'Account Name'}]}
          headers={[
            {key: 'accountName', displayName: 'Account Name', sortable: true},
            {key: 'addonKey', displayName: 'Addon Key', sortable: true},
            {key: 'deviceCount', displayName: 'Device Count', sortable: true},
          ]}
          renderRow={(item) => this.renderRow(item)}
          onTableDataFetch={this.onTableData}
        />

        <SummaryTable data={this.state.tableData} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddonUsage);
