import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import NotificationsSystem, {atalhoTheme, dismissNotification, setUpNotifications} from 'reapop';
import {SemanticToastContainer} from 'react-semantic-toasts';
import Loading from '../../components/loading';
import * as AuthenticationActions from '../../actions/authentication';

import {isNotAuthenticated, isAuthenticated as IsAuthenticated} from '../../middlewares';
import Authenticated from '../authenticated';
import API from '../../lib/api';
import './style.css';

import Login from '../login';

class App extends Component {
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    authenticationActions: PropTypes.object.isRequired,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {token, isAuthenticated} = this.props.authentication;
    const {authenticationActions} = this.props;

    if (token && !isAuthenticated) {
      API.setAuthToken(token);
      authenticationActions.get();
    }

    setUpNotifications({
      defaultProps: {
        position: 'top-right',
        dismissible: true,
        dismissAfter: 3000,
      },
    });
  }

  render() {
    return (
      <div className="container">
        <Loading />
        <Switch>
          <Route path="/login" component={isNotAuthenticated(Login)} />
          <Route path="/" component={IsAuthenticated(Authenticated)} />
        </Switch>
        <NotificationsSystem
          notifications={this.props.notifications}
          dismissNotification={(id) => this.props.dispatch(dismissNotification(id))}
          theme={atalhoTheme}
        />
        <SemanticToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  authenticationActions: bindActionCreators(AuthenticationActions, dispatch),
  dispatch,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
