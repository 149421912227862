import * as accountTypes from '../constants/account';
import API from '../lib/api';
import {handleAPIError} from '../lib/utils';

export function get(id) {
  return (dispatch) =>
    API.getInstance()
      .get(`/account/${id}`)
      .then((response) => {
        dispatch({
          type: accountTypes.ACCOUNT_RECEIVED,
          payload: response.data,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function update(id, accountData) {
  return (dispatch) =>
    API.getInstance()
      .put(`/account/${id}`, accountData)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function create(accountData) {
  return (dispatch) =>
    API.getInstance()
      .post(`/account`, accountData)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function remove(id) {
  return (dispatch) =>
    API.getInstance()
      .delete(`/account/${id}`)
      .then((response) => {
        dispatch({
          type: accountTypes.ACCOUNT_REMOVED,
          payload: {id},
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function mosaic(id) {
  return (dispatch) =>
    API.getInstance()
      .get(`/account/${id}/mosaic`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function listContentImportJobs(accountId, options = {isVQ: false}) {
  return (dispatch) =>
    API.getInstance()
      .get(`/account/${accountId}/content/import`)
      .then((response) => {
        dispatch({
          type: options.isVQ ? accountTypes.VQ_IMPORT_JOBS_LOADED : accountTypes.IMPORT_JOBS_LOADED,
          payload: response.data,
        });

        return response.data;
      })
      .catch((response) => {
        handleAPIError(dispatch)(response);
        throw response;
      });
}

export function importContent(accountId, contentId, options = {isVQ: false}) {
  return (dispatch) =>
    API.getInstance()
      .post(`/account/${accountId}/${options.isVQ ? 'vq-' : ''}content/import`, {contentId})
      .catch((response) => {
        handleAPIError(dispatch)(response);
        throw response;
      });
}
