import React, {Component} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';

export default class AccountTypeSelector extends Component {
  state = {
    items: [
      {key: 'reseller', value: 'reseller', text: 'Reseller'},
      {key: 'customer', value: 'customer', text: 'Customer'},
    ],
  };

  render() {
    const {required, label, input} = this.props;

    const {items} = this.state;

    return (
      <Form.Field required={required}>
        <label>{label}</label>
        <Dropdown
          value={input.value}
          placeholder={label}
          search
          selection
          options={items}
          onChange={(e, {value}) => input.onChange(value)}
        />
      </Form.Field>
    );
  }
}
