import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Table, Button, Label, Icon} from 'semantic-ui-react';
import moment from 'moment';

import FilteredTable from '../../../components/table/filtered-table';

const STATUS_DECORS = {
  active: {color: 'green', icon: 'video play', label: 'Active'},
  suspended: {color: 'yellow', icon: 'pause circle', label: 'Suspended'},
  deactivated: {color: 'red', icon: 'cancel', label: 'Deactivated'},
  deleted: {color: 'gray', icon: 'trash', label: 'Deleted'},
  unknown: {color: 'black', icon: 'exclamation triangle', label: 'Unknown'},
};

const ACCOUNT_STATUSES = [
  {key: 'active', value: 'active', text: 'Active'},
  {key: 'suspended', value: 'suspended', text: 'Suspended'},
  {key: 'deactivated', value: 'deactivated', text: 'Deactivated'},
];

const ACCOUNT_TYPES = [
  {key: 'customer', value: 'customer', text: 'Customer'},
  {key: 'reseller', value: 'reseller', text: 'Reseller'},
];

const ACCOUNT_BUSINESS_LINES = [
  {key: 'signage', value: 'signage', text: 'Signage'},
  {key: 'vq', value: 'vq', text: 'VQ'},
];

const PACKAGE_TYPE_DECORS = {
  starter: {color: 'olive', label: 'Starter'},
  business: {color: 'green', label: 'Business'},
  professional: {color: 'blue', label: 'Professional'},
  enterprise: {color: 'violet', label: 'Enterprise'},
  unknown: {color: 'black', label: 'Unknown'},
};

const DEFAULT_SEARCH_PARAMS = {
  page: 1,
  name: '',
  status: ['active', 'suspended'],
  type: ['reseller', 'customer'],
  businessLine: ['signage', 'vq'],
  sortBy: 'name',
};

class AccountList extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  onNewButtonClicked() {
    const {history} = this.props;
    history.push(`/account/new`);
  }

  onRowClicked(item) {
    const {history} = this.props;
    history.push(`/account/${item.id}/edit`);
  }

  renderRow(item) {
    const statusDecor = STATUS_DECORS[item.status || 'unknown'];
    const packageTypeDecor = PACKAGE_TYPE_DECORS[item.package || 'unknown'];
    const typeName = ACCOUNT_TYPES.find((type) => type.value === item.type).text;
    const accountBusinessLine = ACCOUNT_BUSINESS_LINES.find((type) => type.value === item.businessLine).text;

    return (
      <Table.Row key={item.id} onClick={(event) => this.onRowClicked(item, event)}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{accountBusinessLine}</Table.Cell>
        <Table.Cell>
          {item.type === 'customer' ? (
            <Label tag color={packageTypeDecor.color}>
              {packageTypeDecor.label}
            </Label>
          ) : (
            <>-</>
          )}
        </Table.Cell>
        <Table.Cell>{item.isTrial ? 'Trial' : 'Paid'}</Table.Cell>
        <Table.Cell>
          <Icon size="large" color={statusDecor.color} name={statusDecor.icon} /> {statusDecor.label}
        </Table.Cell>
        <Table.Cell>{typeName}</Table.Cell>
        <Table.Cell>{moment(item.updatedAt).calendar()}</Table.Cell>
        <Table.Cell>{moment(item.createdAt).calendar()}</Table.Cell>
        <Table.Cell>{item.deactivatedAt ? moment(item.deactivatedAt).calendar() : ''}</Table.Cell>
      </Table.Row>
    );
  }

  render() {
    const {history, match, location} = this.props;

    return (
      <div className="view">
        <ul className="post-bar">
          <li>
            <h1>Accounts</h1>
          </li>
          <li>
            <Button onClick={() => this.onNewButtonClicked()} primary>
              New Account
            </Button>
          </li>
        </ul>

        <FilteredTable
          history={history}
          match={match}
          location={location}
          searchRoot="/accounts"
          apiPath="/account"
          defaultSort="name"
          fields={[
            {
              component: 'input',
              name: 'name',
              label: 'Account Name',
              defaultValue: DEFAULT_SEARCH_PARAMS.name,
            },
            {
              component: 'dropdown',
              name: 'status',
              label: 'Status',
              defaultValue: DEFAULT_SEARCH_PARAMS.status,
              parse: (status) => {
                const parsed = status
                  .split(',')
                  .map((s) => s.trim())
                  .filter((s) => !!s);
                return parsed.length > 0 ? parsed : DEFAULT_SEARCH_PARAMS.status;
              },
              props: {selection: true, multiple: true, options: ACCOUNT_STATUSES},
            },
            {
              component: 'dropdown',
              name: 'type',
              label: 'Type',
              defaultValue: DEFAULT_SEARCH_PARAMS.type,
              parse: (type) => {
                const parsed = type
                  .split(',')
                  .map((s) => s.trim())
                  .filter((s) => !!s);
                return parsed.length > 0 ? parsed : DEFAULT_SEARCH_PARAMS.type;
              },
              props: {selection: true, multiple: true, options: ACCOUNT_TYPES},
            },
            {
              component: 'dropdown',
              name: 'businessLine',
              label: 'Business Line',
              defaultValue: DEFAULT_SEARCH_PARAMS.businessLine,
              parse: (businessLine) => {
                const parsed = businessLine
                  .split(',')
                  .map((s) => s.trim())
                  .filter((s) => !!s);
                return parsed.length > 0 ? parsed : DEFAULT_SEARCH_PARAMS.businessLine;
              },
              props: {selection: true, multiple: true, options: ACCOUNT_BUSINESS_LINES},
            },
          ]}
          headers={[
            {key: 'name', displayName: 'Name', sortable: true},
            {key: 'businessLine', displayName: 'Business Line', sortable: true},
            {key: 'package', displayName: 'Package', sortable: true},
            {key: 'isTrial', displayName: 'Trial/Paid', sortable: true},
            {key: 'status', displayName: 'Status', sortable: true},
            {key: 'type', displayName: 'Type', sortable: true},
            {key: 'updatedAt', displayName: 'UpdatedAt', sortable: true},
            {key: 'createdAt', displayName: 'CreatedAt', sortable: true},
            {key: 'deactivatedAt', displayName: 'DeactivatedAt', sortable: true},
          ]}
          renderRow={(item) => this.renderRow(item)}
        />
      </div>
    );
  }
}

export default AccountList;
