import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {reducer as reduxFormReducer} from 'redux-form';
import {reducer as notificationsReducer} from 'reapop';

import authentication from './authentication';
import user from './user';
import account from './account';
import role from './role';

export default combineReducers({
  routerReducer,
  authentication,
  user,
  account,
  role,
  form: reduxFormReducer,
  notifications: notificationsReducer(),
});
