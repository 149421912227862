import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';
import {routerActions} from 'react-router-redux';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
const locationHelper = locationHelperBuilder({});

export const isAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  redirectAction: routerActions.replace,
  authenticatedSelector: (state) => state.authentication.isAuthenticated && !state.authentication.isAuthenticating,
  authenticatingSelector: (state) => state.authentication.isAuthenticating,
  wrapperDisplayName: 'Authenticated',
});

export const isNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  redirectAction: routerActions.replace,
  authenticatedSelector: (state) => !state.authentication.isAuthenticated && !state.authentication.isAuthenticating,
  wrapperDisplayName: 'NotAuthenticated',
});
