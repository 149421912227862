import _ from 'lodash';
import * as roleTypes from '../constants/role';

const initialState = () => [];

export default function roleReducer(state = initialState(), action = {}) {
  switch (action.type) {
    case roleTypes.ROLES_RECEIVED:
      return _.map(action.payload, (role) => ({
        key: role.id,
        value: role.id,
        text: role.name,
      }));
    case roleTypes.ROLES_CLEARED:
      return initialState();
    default:
      return state;
  }
}
