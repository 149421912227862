import * as deviceTypes from '../constants/device';
import API from '../lib/api';
import {handleAPIError} from '../lib/utils';

export function captureScreenShot(id) {
  return (dispatch) =>
    API.getInstance()
      .get(`/device/${id}/screenshot`)
      .then((response) => {
        dispatch({
          type: deviceTypes.CAPTURE_SCREENSHOT,
          payload: response.data,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}
