import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as addonActions from '../../actions/addon';
import config from '../../config';

class Addons extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
  };

  state = {
    addons: [],
    selectedAddonId: null,
  };

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    this.setState({addons: await this.fetchAvailableAddons()});
    this.selectAddon(null);
  }

  async fetchAvailableAddons() {
    const addons = await this.props.addonActions.listAvailables(this.props.accountId);
    return addons.rows;
  }

  renderAddon(addon) {
    const {selectedAddonId} = this.state;
    const lastVersion = addon.versions.slice(-1)[0];
    const addonImageUrl = `${config.CDN_PATH}/addon/${addon.key}/${lastVersion}/logo.png`;

    return (
      <div
        key={`addon_${addon.id}`}
        className={selectedAddonId === addon.id ? 'addon-item selected' : 'addon-item'}
        onClick={() => this.selectAddon(addon.id)}
      >
        <img className="addon-item-image" src={addonImageUrl} alt={addon.id} />
        <div className="addon-item-name">
          {addon.name} {addon.isPublic ? null : `(Not Public)`}
        </div>
        <div className="addon-item-description">{addon.description}</div>
      </div>
    );
  }

  selectAddon(addonId) {
    this.setState({
      selectedAddonId: addonId,
    });
    this.props.selectAddon(addonId);
  }

  render() {
    const {addons} = this.state;

    return (
      <div className="addon-container">
        <div className="addon">{addons.map((addon) => this.renderAddon(addon))}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addonActions: bindActionCreators(addonActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Addons);
