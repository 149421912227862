import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as widgetActions from '../../actions/widget';
import config from '../../config';

class Widgets extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
  };

  state = {
    widgets: [],
    selectedWidgetId: null,
  };

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    this.setState({widgets: await this.fetchAvailableWidgets()});
    this.selectWidget(null);
  }

  async fetchAvailableWidgets() {
    const widgets = await this.props.widgetActions.listAvailables(this.props.accountId);
    return widgets.rows;
  }

  renderWidget(widget) {
    const {selectedWidgetId} = this.state;
    const lastVersion = widget.versions.slice(-1)[0];
    const widgetImageUrl = `${config.CDN_PATH}/widget/${widget.key}/${lastVersion}/logo.png`;

    return (
      <div
        key={`widget_${widget.id}`}
        className={selectedWidgetId === widget.id ? 'addon-item selected' : 'addon-item'}
        onClick={() => this.selectWidget(widget.id)}
      >
        <img className="addon-item-image" src={widgetImageUrl} alt={widget.id} />
        <div className="addon-item-name">
          {widget.name} {widget.isPublic ? null : `(Not Public)`}
        </div>
        <div className="addon-item-description">{widget.description}</div>
      </div>
    );
  }

  selectWidget(widgetId) {
    this.setState({
      selectedWidgetId: widgetId,
    });
    this.props.selectWidget(widgetId);
  }

  render() {
    const {widgets} = this.state;

    return (
      <div className="addon-container">
        <div className="addon">{widgets.map((widget) => this.renderWidget(widget))}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  widgetActions: bindActionCreators(widgetActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Widgets);
