import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/account';
import config from '../../config';

class Mosaic extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
  };

  state = {
    devices: [],
    timestamp: Date.now(),
    fetching: true,
  };

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    this.setState({
      devices: await this.fetchDevices(),
      fetching: false,
    });
  }

  fetchDevices() {
    return this.props.accountActions.mosaic(this.props.accountId);
  }

  refresh() {
    this.setState({fetching: true});

    this.fetchDevices().then((devices) => {
      this.setState({
        fetching: false,
        timestamp: Date.now(),
        devices,
      });
    });
  }

  renderScreenshot(device) {
    const {timestamp, fetching} = this.state;
    const url = device.isOnline
      ? `${config.API_PATH}/${config.API_VERSION}/device/${device.id}/screenshot?width=192&height=108&ts=${timestamp}`
      : '';

    return (
      <div key={`mosaic_${device.id}`} className="mosaic-item">
        <div className="mosaic-item-id">{device.id}</div>
        {fetching ? <div className="mosaic-fetching">FETCHING</div> : null}

        {device.isOnline ? (
          <img className="mosaic-item-screenshot" src={url} alt={device.id} />
        ) : (
          <div className="mosaic-offline">OFFLINE</div>
        )}
      </div>
    );
  }

  render() {
    const {devices} = this.state;

    return (
      <div className="mosaic-container">
        <Button style={{alignSelf: 'center', marginBottom: '40px'}} onClick={() => this.refresh()}>
          Refresh
        </Button>
        <div className="mosaic">{devices.map((device) => this.renderScreenshot(device))}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mosaic);
