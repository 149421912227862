import React from 'react';
import {Form, Dropdown} from 'semantic-ui-react';

export const options = [
  {key: 'authy', value: 'authy', text: 'Authy'},
  {key: 'gauth', value: 'gauth', text: 'Gauth'},
  {key: 'none', value: 'none', text: 'None'},
];

const TwoFactorSelectorForm = ({required, label, input}) => (
  <Form.Field required={required}>
    <label>{label}</label>
    <Dropdown
      value={input.value || options[0].value}
      placeholder={label}
      search
      selection
      options={options}
      onChange={(e, {value}) => input.onChange(value)}
    />
  </Form.Field>
);

export default TwoFactorSelectorForm;
