import * as userTypes from '../constants/user';

const initialState = () => ({});

export default function userReducer(state = initialState(), action = {}) {
  switch (action.type) {
    case userTypes.USER_RECEIVED:
      return {...state, [action.payload.id]: action.payload};
    case userTypes.USER_REMOVED:
      return {...state, [action.payload.id]: undefined};
    default:
      return state;
  }
}
