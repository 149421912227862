import _ from 'lodash';
import React, {Component} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as roleActions from '../../actions/role';

class RoleSelectorFormInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: props.role,
      blockUI: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (!this.props.role.length) this.props.roleActions.getAll();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEqual(nextProps.role, this.state.roles)) return;

    this.setState({roles: nextProps.role});
  }

  render() {
    const {required, label, input} = this.props;

    const {blockUI, roles} = this.state;

    return (
      <Form.Field required={required}>
        <label>{label}</label>
        <Dropdown
          value={input.value}
          placeholder={label}
          search
          selection
          options={roles}
          disabled={blockUI}
          onChange={(e, {value}) => input.onChange(value)}
        />
      </Form.Field>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  roleActions: bindActionCreators(roleActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleSelectorFormInput);
