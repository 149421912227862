import * as userTypes from '../constants/user';
import API from '../lib/api';
import {handleAPIError} from '../lib/utils';

export function get(id) {
  return (dispatch) =>
    API.getInstance()
      .get(`/user/${id}`)
      .then((response) => {
        dispatch({
          type: userTypes.USER_RECEIVED,
          payload: response.data,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function update(id, user) {
  return (dispatch) =>
    API.getInstance()
      .put(`/user/${id}`, user)
      .then((response) => {
        dispatch({
          type: userTypes.USER_RECEIVED,
          payload: response.data,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function addNewUserToAccount(user, accountId, roleId) {
  return (dispatch) =>
    API.getInstance()
      .post(`/user`, user)
      .then((response) => {
        dispatch({
          type: userTypes.USER_RECEIVED,
          payload: response.data,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function addExistingUserToAccount(userId, accountId, roleId) {
  return (dispatch) =>
    API.getInstance()
      .post(`/user/${userId}/account/${accountId}/role/${roleId}`)
      .then((response) => {
        dispatch({
          type: userTypes.USER_RECEIVED,
          payload: response.data,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function removeFromAccount(userId, accountId) {
  return (dispatch) =>
    API.getInstance()
      .delete(`/user/${userId}/account/${accountId}`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function inviteUserToAccount(email, roleId, accountId) {
  return (dispatch) =>
    API.getInstance()
      .post('/user/invitation', {email, roleId, accountId})
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function deleteInvitation(invitationId) {
  return (dispatch) =>
    API.getInstance()
      .delete(`/user/invitation/${invitationId}`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function renewInvitation(invitationId) {
  return (dispatch) =>
    API.getInstance()
      .post(`/user/invitation/${invitationId}/renew`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}
