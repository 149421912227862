import Fingerprint2 from 'fingerprintjs2';

async function waitUntilIdle() {
  let whenIdle = window.requestIdleCallback;

  if (!whenIdle) {
    whenIdle = function whenIdle(cb) {
      return setTimeout(cb, 200);
    };
  }

  return new Promise((resolve) => {
    whenIdle(resolve);
  });
}

async function getUserHash() {
  try {
    const components = await Fingerprint2.getPromise({});
    const values = components.map((component) => component.value);
    return Fingerprint2.x64hash128(values.join(''), 31);
  } catch (err) {
    console.log('Error getting fingerprint', err);
    return null;
  }
}

export async function autoDetect() {
  await waitUntilIdle();
  return getUserHash();
}
