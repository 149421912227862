import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';
import Store from './store';

import './style.css';
import 'semantic-ui-css/semantic.min.css';
import 'font-awesome/css/font-awesome.min.css';

import 'react-semantic-toasts/styles/react-semantic-alert.css';

import * as authenticationActions from './actions/authentication';

import App from './views/app';

import 'babel-polyfill';

authenticationActions.checkAuth()(Store.getInstance().dispatch);

ReactDOM.render(
  <Provider store={Store.getInstance()}>
    <ConnectedRouter history={Store.getHistory()}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
