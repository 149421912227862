import * as authenticationTypes from '../constants/authentication';
import API from '../lib/api';
import {handleAPIError} from '../lib/utils';

export function login(email, password, fingerprintHash = null) {
  return (dispatch) =>
    API.getInstance()
      .post('/authentication/login', {email, password, fphash: fingerprintHash})
      .then((response) => {
        if (response.status === 202) {
          dispatch({
            type: authenticationTypes.LOGGED_IN,
            payload: response.data,
          });
        }

        return response;
      })
      .catch(handleAPIError(dispatch));
}

export function sendLoginVerificationToken(token) {
  return (dispatch) =>
    API.getInstance()
      .post('/authentication/login-verification', {token})
      .then((response) => {
        dispatch({
          type: authenticationTypes.LOGGED_IN,
          payload: response.data,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function logout() {
  return (dispatch) =>
    API.getInstance()
      .post('/authentication/logout')
      .then((response) => {
        dispatch({
          type: authenticationTypes.LOGGED_OUT,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function getCurrentUser() {
  return (dispatch) =>
    API.getInstance()
      .get('/authentication/me')
      .then((response) => {
        dispatch({
          type: authenticationTypes.GOT_CURRENT_USER,
          payload: response.data,
        });

        return response.data;
      })
      .catch(handleAPIError(dispatch));
}

export function checkAuth() {
  return (dispatch) =>
    API.getInstance()
      .get('/authentication/me')
      .then((response) => {
        dispatch({
          type: authenticationTypes.GOT_CURRENT_USER,
          payload: response.data,
        });

        return response.data;
      })
      .catch((err) => {
        dispatch({
          type: authenticationTypes.NOT_AUTHENTICATED,
        });
      });
}
