import _ from 'lodash';
import React, {Component} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';
import API from '../../lib/api';

export default class UserSelectorFormInput extends Component {
  state = {
    users: [],
    blockUI: false,
    accountFilterId: null,
  };

  constructor(props) {
    super(props);
    this.onSearchChangedDebounced = _.debounce(this.onSearchChange.bind(this), 500);
  }

  fetch(accountId = null) {
    const {includeAllOption} = this.props;

    return new Promise((resolve, reject) => {
      this.setState({blockUI: true}, () => {
        if (accountId && accountId !== '*') {
          return API.getInstance()
            .get(`/account/${accountId}/user`, {params: {limit: this.props.limit || 10}})
            .then((response) =>
              _.map(response.data.rows, (user) => ({
                key: user.UserId,
                value: user.UserId,
                text: `${user.name} (${user.email})`,
              })),
            )
            .then((users) => {
              if (includeAllOption) users.unshift({key: '*', value: '*', text: 'ALL'});

              this.setState({users, blockUI: false});
              resolve();
            });
        }

        API.getInstance()
          .get('/user', {params: {name: this.state.name, limit: this.props.limit || 10}})
          .then((response) => {
            const users = _.map(response.data.rows, (user) => ({
              key: user.id,
              value: user.id,
              text: `${user.name} (${user.email})`,
            }));

            // If no users found check by email
            if (!users.length) {
              return API.getInstance()
                .get('/user', {params: {email: this.state.name, limit: this.props.limit || 10}})
                .then((res) =>
                  _.map(res.data.rows, (user) => ({
                    key: user.id,
                    value: user.id,
                    text: `${user.name} (${user.email})`,
                  })),
                );
            }
            return users;
          })
          .then((users) => {
            if (includeAllOption) users.unshift({key: '*', value: '*', text: 'ALL'});

            this.setState({users, blockUI: false});
            resolve();
          })
          .catch(reject);
      });
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.fetch();
  }

  onSearchChange(query) {
    this.setState({name: query}, () => {
      this.fetch();
    });
  }

  render() {
    const {required, label, onChange, key, name, value, input, filteringId} = this.props;

    const {blockUI, users, accountFilterId} = this.state;

    if (filteringId) {
      if (filteringId !== accountFilterId && filteringId !== '*') {
        this.fetch(filteringId);
        this.setState({accountFilterId: filteringId});
      } else if (filteringId === '*' && filteringId !== accountFilterId) {
        this.fetch();
        this.setState({accountFilterId: filteringId});
      }
    }

    const intValue = parseInt(value, 10);

    return (
      <Form.Field required={required}>
        <label>{label}</label>
        <Dropdown
          key={key}
          name={name}
          placeholder={label}
          search
          selection
          options={users}
          disabled={blockUI}
          onChange={input ? (e, {value: formValue}) => input.onChange(formValue) : onChange}
          value={input ? input.value : intValue || value}
          onSearchChange={(e, data) => this.onSearchChangedDebounced(data.searchQuery)}
        />
      </Form.Field>
    );
  }
}
