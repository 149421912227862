import React from 'react';
import {connect} from 'react-redux';
import {Icon} from 'semantic-ui-react';
import backgroundImage from '../../images/home.jpg';

const Home = () => (
  <div className="view">
    <div
      style={{
        fontSize: '18pt',
        marginBottom: '20px',
        fontStyle: 'italic',
      }}
    >
      Mix All, Sync All <Icon name="american sign language interpreting" />
    </div>
    <img style={{maxWidth: '100%'}} src={backgroundImage} alt="OMMA" />
  </div>
);

export default connect()(Home);
