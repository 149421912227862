import {notify} from 'reapop';

const handleAPIError = (dispatch) => (payload) => {
  if (!payload || !payload.response) {
    return dispatch(notify('Could not connect to server, please try again', 'error'));
  }

  if (payload.response.data) {
    const {error} = payload.response.data;
    console.log(payload.response.data);
    return dispatch(notify(error, 'error'));
  }

  const {statusText} = payload.response;
  dispatch(notify(statusText, 'error'));
};

export {handleAPIError};
