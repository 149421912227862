import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Table} from 'semantic-ui-react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import extend from 'lodash/extend';

import API from '../../lib/api';
import Pagination from '../pagination';

export default class APITable extends Component {
  static propTypes = {
    updateOnPropChange: PropTypes.bool,
    apiPath: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    query: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    renderHeader: PropTypes.func,
    renderRow: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    onRowClicked: PropTypes.func,
    onTableDataFetch: PropTypes.func,
  };

  state = {
    shouldFetch: false,
    isLoading: true,
    rows: [],
    column: null,
    direction: null,
    currentPage: 1,
    itemsPerPage: 25,
    count: 0,
    maxPageMarkerCount: 9,
  };

  constructor(props) {
    super(props);

    if (props.currentPage) {
      this.state.currentPage = props.currentPage;
    }
  }

  static getDerivedStateFromProps(props, state) {
    const fromProps = pick(props, ['query', 'sortBy', 'currentPage', 'itemsPerPage', 'maxPageMarkerCount']);
    const fromState = pick(state, ['query', 'sortBy', 'currentPage', 'itemsPerPage', 'maxPageMarkerCount']);

    if (isEqual(fromProps, fromState)) {
      return {...state, shouldFetch: false};
    }

    const {sortBy} = fromProps;
    let column = null;
    let direction = null;

    if (sortBy) {
      direction = sortBy[0] === '-' ? 'descending' : 'ascending';
      column = sortBy.replace(/^\-+/g, ''); // eslint-disable-line
    }

    return extend({}, state, fromProps, {column, direction, sortBy});
  }

  componentDidMount() {
    this.setState({shouldFetch: true});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.shouldFetch) {
      this.fetch();
    }
  }

  shouldComponentUpdate(props, state) {
    if (props.updateOnPropChange && !isEqual(props, this.props)) {
      return true;
    }

    return !isEqual(state, this.state);
  }

  fetch() {
    const {query = {}, sortBy, currentPage, itemsPerPage} = this.state;

    return new Promise((resolve, reject) => {
      this.setState({isLoading: true, shouldFetch: false}, () => {
        const params = {
          limit: itemsPerPage,
          offset: (currentPage - 1) * itemsPerPage,
          sortBy,
          ...query,
        };

        API.getInstance()
          .get(this.props.apiPath, {params})
          .then((response) => {
            const {rows, count} = response.data;
            this.setState({
              currentPage,
              rows,
              count,
              isLoading: false,
            });
            this.onTableData(rows);
            resolve();
          })
          .catch((err) => reject(err));
      });
    });
  }

  onTableData(tableData) {
    if (this.props.onTableDataFetch) {
      this.props.onTableDataFetch(tableData);
    }
  }

  onPaginationClicked = (page) => {
    this.setState({currentPage: page, shouldFetch: true});
  };

  onSortHeaderClicked = (item) => {
    const {key: clickedColumn, sortable} = item;
    if (!sortable) return;

    const {onSortOrderChanged} = this.props;
    let {column, direction} = this.state;

    if (column !== clickedColumn) {
      direction = 'ascending';
      column = clickedColumn;
    } else {
      direction = direction === 'ascending' ? 'descending' : 'ascending';
    }

    const sortBy = (direction === 'ascending' ? '' : '-') + column;

    if (onSortOrderChanged) {
      onSortOrderChanged({column, direction, sortBy});
    } else {
      this.setState({
        shouldFetch: true,
        sortBy,
        column,
        direction,
      });
    }
  };

  renderHeader() {
    const {column, direction} = this.state;
    const headers = this.props.headers ? this.props.headers : [{key: 'item', displayName: 'Item'}];

    return (
      <Table.Header>
        <Table.Row>
          {_.map(headers, (item) => (
            <Table.HeaderCell
              style={item.style}
              key={`table_header_column_${item.key}`}
              sorted={column === item.key ? direction : null}
              onClick={() => this.onSortHeaderClicked(item)}
            >
              {item.displayName}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
    );
  }

  renderBody() {
    const {rows, isLoading = false} = this.state;
    const colspan = this.props.headers ? this.props.headers.length : 1;

    let rowsMarkup = (
      <Table.Row>
        <Table.Cell className="empty-row" colSpan={colspan || 1}>
          {isLoading ? 'Loading...' : 'Empty'}
        </Table.Cell>
      </Table.Row>
    );

    if (rows.length) rowsMarkup = rows.map((item) => this.renderRow(item));

    return <Table.Body>{rowsMarkup}</Table.Body>;
  }

  renderRow(item) {
    if (this.props.renderRow) {
      return this.props.renderRow(item);
    }

    return (
      <Table.Row key={`table_row_${item}`}>
        <Table.Cell>{JSON.stringify(item)}</Table.Cell>
      </Table.Row>
    );
  }

  renderFooter() {
    const {currentPage = 1, itemsPerPage, count, maxPageMarkerCount} = this.state;
    const {paginationPath, onPaginationClicked = this.onPaginationClicked} = this.props;

    return (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={this.props.headers ? this.props.headers.length : 1}>
            <Pagination
              floated="right"
              currentPage={currentPage}
              totalItems={count}
              itemsPerPage={itemsPerPage}
              path={paginationPath}
              onClick={onPaginationClicked}
              maxDisplayedPageCount={maxPageMarkerCount}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  }

  render() {
    const {isLoading = false} = this.state;
    const pointerEvents = isLoading ? 'none' : 'all';
    return (
      <div>
        <Table sortable selectable celled style={{pointerEvents}}>
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </Table>
      </div>
    );
  }
}
