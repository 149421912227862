import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Dropdown} from 'semantic-ui-react';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import extend from 'lodash/extend';
import get from 'lodash/get';
import APITable from './index';

class FilteredTable extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,

    searchRoot: PropTypes.string,
    apiPath: PropTypes.string,
    defaultSort: PropTypes.string,
    headers: PropTypes.arrayOf(PropTypes.object),
    itemsPerPage: PropTypes.number,
    updateOnPropChange: PropTypes.bool,
    extraSearchParams: PropTypes.object,
    inline: PropTypes.object,

    renderRow: PropTypes.func,
    onFilterFormSubmit: PropTypes.func,
    onReady: PropTypes.func,
    onFilterChange: PropTypes.func,
    onTableDataFetch: PropTypes.func,
  };

  state = {
    query: {},
    searchParams: {},
  };

  static getDerivedStateFromProps(props, state) {
    const {
      location: {search},
      match: {params},
    } = props;
    const queryFromProps = {search, params};

    if (isEqual(queryFromProps, state.query)) {
      return state;
    }

    const searchParams = {};
    const queryParams = queryString.parse(props.location.search);
    const {page = 1, sortBy = props.defaultSort || '', pageSize = null} = queryParams;

    if (sortBy) {
      searchParams.sortBy = sortBy;
    }
    if (!Number.isNaN(page)) {
      searchParams.page = Number(page);
    }
    if (!Number.isNaN(pageSize)) {
      searchParams.pageSize = Number(pageSize);
    }

    const fieldMap = {};
    props.fields.forEach((field) => {
      const {name, parse, defaultValue} = field;
      const queryKey = field.queryKey || name;
      const queryValue = queryParams[queryKey] || '';

      let actualValue = queryValue || defaultValue;
      if (parse) {
        actualValue = parse(queryValue);
      }

      fieldMap[name] = field;
      searchParams[name] = actualValue;
    });

    return extend({}, state, {query: queryFromProps, searchParams, fieldMap});
  }

  componentDidMount() {
    if (this.props.onReady) {
      this.props.onReady(this.state);
    }
  }

  navigate(updated = {}) {
    const {history, searchRoot, extraSearchParams = {}} = this.props;
    const {searchParams} = this.state;
    history.push(`${searchRoot}${this.getParamsAsQueryString({...searchParams, ...extraSearchParams, ...updated})}`);
  }

  onFilterFormSubmitted = () => {
    this.navigate({page: 1});

    const {onFilterFormSubmit} = this.props;
    if (onFilterFormSubmit) onFilterFormSubmit(this.state.searchParams);
  };

  onFilterChanged = (e, {name, value}) => {
    const {searchParams} = this.state;

    const {onFilterChange} = this.props;
    if (onFilterChange) {
      onFilterChange(value, searchParams[name]);
    }

    const onChange = get(this.state, `fieldMap[${name}].onChange`);
    if (onChange) {
      onChange(value, searchParams[name]);
    }

    this.setState({
      searchParams: {
        ...searchParams,
        [name]: value,
      },
    });
  };

  getSanitizedSearchParams(searchParams = this.state.searchParams) {
    const query = {};

    Object.keys(searchParams).forEach((key) => {
      const field = get(this.state, `fieldMap[${key}]`) || {};
      const {defaultValue, querify} = field;
      if (!searchParams.hasOwnProperty(key) || isEqual(searchParams[key], defaultValue)) {
        return;
      }

      const value = searchParams[key];
      query[key] = querify ? querify(value) : value;
    });

    return query;
  }

  getParamsAsQueryString(searchParams = this.state.searchParams) {
    const query = this.getSanitizedSearchParams(searchParams);
    const asString = queryString.stringify(query, {arrayFormat: 'comma'});
    return asString ? `?${asString}` : '';
  }

  renderField({key, name, label, component: FieldComponent, props = {}, includeAllOption = false, filteringId = null}) {
    const value = get(this.state, `searchParams.${name}`);
    const finalProps = extend({}, props, {
      name,
      label,
      value,
      onChange: this.onFilterChanged,
      includeAllOption,
      filteringId,
    });

    switch (FieldComponent) {
      case 'input':
      case Input:
        FieldComponent = Input;
        delete finalProps.value;
        finalProps.defaultValue = value;
        break;

      case 'dropdown':
        FieldComponent = Dropdown;
        break;

      default:
    }

    // Label for the components that doesn't have it by default
    const additionalLabel = <label>{label}</label>;

    return (
      <Form.Field style={{display: 'flex', alignItems: 'center'}} key={key || name}>
        {FieldComponent === Dropdown && additionalLabel}
        <FieldComponent {...finalProps} />
      </Form.Field>
    );
  }

  renderFilters() {
    let {fields = [], inline = true} = this.props;
    fields = fields.filter((field) => (field.hasOwnProperty('rendered') ? field.rendered : true));

    if (fields.length < 1) {
      return null;
    }

    return (
      <Form onSubmit={this.onFilterFormSubmitted} style={{marginTop: 14}}>
        <Form.Group inline={inline}>
          {fields.map((field) => this.renderField(field))}
          <Button type="submit">Search</Button>
        </Form.Group>
      </Form>
    );
  }

  onSortOrderChanged = ({sortBy}) => {
    this.navigate({sortBy});
  };

  onTableData = (tableData) => {
    if (this.props.onTableDataFetch) {
      this.props.onTableDataFetch(tableData);
    }
  };

  render() {
    const {searchParams} = this.state;
    const {
      style,
      className,
      searchRoot,
      apiPath,
      headers,
      renderRow,
      extraSearchParams = {},
      itemsPerPage = 25,
      hideFilters = false,
    } = this.props;

    return (
      <div style={style} className={className}>
        {!hideFilters && this.renderFilters(searchParams)}
        <APITable
          {...this.props}
          apiPath={apiPath}
          headers={headers}
          renderRow={renderRow}
          paginationPath={(page) =>
            `${searchRoot}${this.getParamsAsQueryString({...searchParams, ...extraSearchParams, page})}`
          }
          currentPage={searchParams.page}
          itemsPerPage={itemsPerPage}
          query={searchParams}
          sortBy={searchParams.sortBy}
          onSortOrderChanged={this.onSortOrderChanged}
          onTableDataFetch={this.onTableData}
        />
      </div>
    );
  }
}

export default FilteredTable;
