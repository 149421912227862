import {Form} from 'semantic-ui-react';
import {InputField} from 'react-semantic-redux-form';
import React from 'react';
import {Field, reduxForm} from 'redux-form';
import RoleSelectorFormInput from '../../../components/roleselector/form';

const InviteUserForm = (props) => {
  const {handleSubmit, pristine, submitting} = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Field component={InputField} name="email" placeholder="Email" label="Email" type="email" required />

      <Field component={RoleSelectorFormInput} name="roleId" placeholder="role" label="Role" required />

      <Form.Button type="submit" disabled={pristine || submitting}>
        Send Invitation
      </Form.Button>
    </Form>
  );
};

const validator = (values) => {
  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  return {
    email: !emailRegex.test(values.email) && 'Please provide a valid email address.',
  };
};

export default reduxForm({
  form: 'InviteUser_Form',
  enableReinitialize: true,
  validate: validator,
})(InviteUserForm);
