import {Form, Button, Dropdown, Divider} from 'semantic-ui-react';
import {InputField, TextAreaField, CheckboxField} from 'react-semantic-redux-form';
import React from 'react';
import {connect} from 'react-redux';
import {Field, FieldArray, reduxForm, formValueSelector} from 'redux-form';
import PackageSelectorFormInput from '../../../components/packageselector/form';
import AccountStatusSelector from '../../../components/accountstatusselector/form';
import AccountBusinessLineSelector from '../../../components/accountbusinesslineselector/form';
import AccountTypeSelector from '../../../components/accounttypeselector/form';
import AccountParentSelector from '../../../components/accountparentselector/form';
import TrialPeriodEndsAt from '../../../components/trialperiodendsat/form';
const ipaddr = require('ipaddr.js');

const renderGroupingType = (props) => (
  <div style={{paddingBottom: 10}}>
    <label style={{marginRight: 15, paddingLeft: 5}}>Send mail grouped by:</label>
    <Dropdown
      value={props.input.value}
      selection
      options={[
        {key: 'device', value: 'device', text: 'device (individual)'},
        {key: 'shop', value: 'shop', text: 'shop'},
        {key: 'account', value: 'account', text: 'account'},
      ]}
      onChange={(e, {value}) => props.input.onChange(value)}
    />
  </div>
);

const renderMaxOfflineTime = (props) => (
  <div style={{paddingBottom: 10}}>
    <label style={{marginRight: 15, paddingLeft: 5}}>Offline time threshold:</label>
    <Dropdown
      value={props.input.value}
      selection
      options={[
        {key: '15 mins', value: 900000, text: '15 mins'},
        {key: '30 mins', value: 1800000, text: '30 mins'},
        {key: '45 mins', value: 2700000, text: '45 mins'},
        {key: '1 hour', value: 3600000, text: '1 hour'},
        {key: '2 hours', value: 7200000, text: '2 hours'},
        {key: '4 hours', value: 14400000, text: '4 hours'},
        {key: '8 hours', value: 28800000, text: '8 hours'},
        {key: '1 day', value: 86400000, text: '1 day'},
      ]}
      onChange={(e, {value}) => props.input.onChange(value)}
    />
  </div>
);

const renderEmailField = ({fields, input, index, meta: {touched, error}}) => (
  <div style={{marginBottom: 10}}>
    <div style={{marginBottom: 5}}>
      <input {...input} type="text" placeholder="your email adress" style={{width: '50%', marginRight: 5}} />
      <Button circular type="button" icon="remove" size="small" onClick={() => fields.remove(index)} />
    </div>
    {touched && error && <span style={{color: '#fa0500', fontWeight: 'bold'}}>{error}</span>}
  </div>
);

const renderEmailTo = ({fields}) => (
  <div style={{display: 'flex'}}>
    <label style={{marginRight: 15, paddingLeft: 5, paddingTop: 5}}>Email to:</label>
    <div style={{flex: '1'}}>
      {fields.map((email, index) => (
        <div key={`email-${index}`}>
          <Field name={email} component={renderEmailField} fields={fields} index={index} required={false} />
        </div>
      ))}
      <Button type="button" size="small" onClick={() => fields.push()}>
        Add mail
      </Button>
    </div>
  </div>
);

const renderOfflineDeviceAlarmConfig = (props) => (
  <div className="field">
    <label style={{paddingBottom: 10}}>Offline device alarm configuration</label>
    <Field component={CheckboxField} label="Enable" name="configuration.offlineAlarm.enabled" toggle />
    <Field component={renderGroupingType} name="configuration.offlineAlarm.alarmGroupingType" />
    <Field component={renderMaxOfflineTime} name="configuration.offlineAlarm.maxOfflineTime" />
    <FieldArray component={renderEmailTo} name="configuration.offlineAlarm.emailTo" />
  </div>
);

const renderUserWhitelist = ({fields}) => (
  <div className="field">
    <label style={{paddingBottom: 10}}>User IP Whitelist</label>
    {fields.map((ip, index) => (
      <div key={`user-whitelist-${index}`}>
        <Field
          name={ip}
          component={({fields, input, index, meta: {touched, error}}) => (
            <div style={{marginBottom: 10}}>
              <div style={{marginBottom: 5}}>
                <input {...input} type="text" placeholder="e.g. 10.12.0.1/16" style={{width: '50%', marginRight: 5}} />
                <Button circular type="button" icon="remove" size="small" onClick={() => fields.remove(index)} />
              </div>
              {touched && error && <span style={{color: '#fa0500', fontWeight: 'bold'}}>{error}</span>}
            </div>
          )}
          fields={fields}
          index={index}
        />
      </div>
    ))}
    <Button type="button" onClick={() => fields.push()}>
      Add ip
    </Button>
  </div>
);

const renderAllowedSourcesForDatasource = ({fields}) => (
  <div className="field">
    <label style={{paddingBottom: 10}}>Allowed IP/Domains in Datasources</label>
    {/* TODO: Put a tooltip and explain what feature does */}
    {fields.map((ipOrDomain, index) => (
      <div key={`allowed-datasource-url-${index}`}>
        <Field
          name={ipOrDomain}
          component={({fields, input, index, meta: {touched, error}}) => (
            <div style={{marginBottom: 10}}>
              <input {...input} type="text" placeholder="e.g. 10.12.0.1/16" style={{width: '50%', marginRight: 5}} />
              <Button circular type="button" icon="remove" size="small" onClick={() => fields.remove(index)} />
              {input.value === '*' && (
                <div style={{color: '#ebaf00', fontWeight: 'bold'}}>Star (*) means all IP/Domains are allowed</div>
              )}
              {touched && error && <div style={{color: '#fa0500', fontWeight: 'bold'}}>{error}</div>}
            </div>
          )}
          fields={fields}
          index={index}
        />
      </div>
    ))}
    {fields.getAll().length === 0 && (
      <>
        <div style={{color: '#ebaf00', fontWeight: 'bold'}}>No entry means all IP/domains will be blocked</div>
        <div style={{color: '#ebaf00', fontWeight: 'bold'}}>Add star (*) symbol if you want to allow all</div>
      </>
    )}
    <Button
      type="button"
      style={{marginTop: '10px'}}
      onClick={() => {
        if (fields.getAll().includes('*')) return;
        fields.push();
      }}
    >
      Add IP/Domain
    </Button>
  </div>
);

const renderAllowedSourcesForImportingMedia = ({fields}) => (
  <div className="field">
    <label style={{paddingBottom: 10}}>Allowed IP/Domains when Importing Media</label>
    {/* TODO: Put a tooltip and explain what feature does */}
    {fields.map((ipOrDomain, index) => (
      <div key={`allowed-media-url-${index}`}>
        <Field
          name={ipOrDomain}
          component={({fields, input, index, meta: {touched, error}}) => (
            <div style={{marginBottom: 10}}>
              <input {...input} type="text" placeholder="e.g. 10.12.0.1/16" style={{width: '50%', marginRight: 5}} />
              <Button circular type="button" icon="remove" size="small" onClick={() => fields.remove(index)} />
              {input.value === '*' && (
                <div style={{color: '#ebaf00', fontWeight: 'bold'}}>Star (*) means all IP/Domains are allowed</div>
              )}
              {touched && error && <div style={{color: '#fa0500', fontWeight: 'bold'}}>{error}</div>}
            </div>
          )}
          fields={fields}
          index={index}
        />
      </div>
    ))}
    {fields.getAll().length === 0 && (
      <>
        <div style={{color: '#ebaf00', fontWeight: 'bold'}}>No entry means all IP/domains will be blocked</div>
        <div style={{color: '#ebaf00', fontWeight: 'bold'}}>Add star (*) symbol if you want to allow all</div>
      </>
    )}
    <Button
      type="button"
      style={{marginTop: '10px'}}
      onClick={() => {
        if (fields.getAll().includes('*')) return;
        fields.push();
      }}
    >
      Add IP/Domain
    </Button>
  </div>
);

const validator = (values) => {
  const deviceLimitation = parseInt(values.limitations.device, 10);

  const userWhitelistErrors = [];
  if (values.configuration.userWhitelist) {
    for (const [index, ipItem] of values.configuration.userWhitelist.entries()) {
      try {
        /** If the IP item is a single valid IP*/
        if (ipaddr.isValid(ipItem)) {
          continue;
        } else {
          /** If the IP item is a valid range (i.e. 192.168.1.1/16) */
          ipaddr.parseCIDR(ipItem);
        }
      } catch (err) {
        userWhitelistErrors[index] = 'Please enter a valid single IP or an CIDR IP range.';
      }
    }
  }

  let offlineAlarmErrors;
  if (values.configuration.offlineAlarm.emailTo.length) {
    const offlineAlarmMailErrors = [];
    for (const [index, mail] of values.configuration.offlineAlarm.emailTo.entries()) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mail))
        offlineAlarmMailErrors[index] = 'Please enter a valid e-mail address.';
    }
    if (offlineAlarmMailErrors.length) {
      offlineAlarmErrors = {emailTo: offlineAlarmMailErrors};
    }
  }
  return {
    limitations: {
      device: (deviceLimitation < 0 || deviceLimitation > 65536) && 'Must be in 0 - 65536 (including)',
    },
    configuration: {
      userWhitelist: userWhitelistErrors,
      offlineAlarm: offlineAlarmErrors,
    },
  };
};

const AccountEdit = (props) => {
  const {handleSubmit} = props;
  return (
    <Form onSubmit={handleSubmit}>
      <Field component={InputField} name="name" placeholder="Name" label="Name" />

      <Field
        component={AccountBusinessLineSelector}
        disabled={!!props.initialValues.id}
        name="businessLine"
        placeholder="Business Line"
        label="Business Line"
        required
      />

      <Field component={AccountTypeSelector} name="type" placeholder="Type" label="Type" required />

      <Field
        component={AccountParentSelector}
        name="parentId"
        placeholder="Parent Account"
        label="Parent Account"
        required={props.businessLine === 'signage'}
      />

      <Field
        component={PackageSelectorFormInput}
        name="package"
        placeholder="Package"
        label="Package"
        required={props.type === 'customer'}
        disabled={props.type !== 'customer'}
      />

      <Field component={CheckboxField} name="isTrial" label="Trial Period" required={false} toggle />

      {props.isTrial && (
        <Field component={TrialPeriodEndsAt} name="trialPeriodEndsAt" label="Trial Period End Date" required />
      )}

      <Field component={AccountStatusSelector} name="status" placeholder="Account Status" label="Account Status" />

      <Field
        component={InputField}
        name="limitations.device"
        placeholder="Device Limitation"
        label="Device Limitation"
        required
      />

      <Field component={TextAreaField} name="notes" placeholder="Notes" label="Notes" rows={2} />

      <Field
        component={CheckboxField}
        name="configuration.crossoverEnabledByDefault"
        label="Enable Player CrossOver"
        required={false}
        toggle
      />
      <Field
        component={CheckboxField}
        name="configuration.restartOnFreeze"
        label="Restart Device on Freeze"
        required={false}
        toggle
      />
      <Field
        component={CheckboxField}
        name="configuration.defaultDeviceAnalytics"
        label="Enable Analytics for New Devices"
        required={false}
        toggle
      />
      <Field
        component={CheckboxField}
        name="configuration.skipAppUpdateOnAdoption"
        label="Skip Auto App Update on Register"
        required={false}
        toggle
      />

      <Divider style={{width: '100%'}} />

      <Field component={renderOfflineDeviceAlarmConfig} name="configuration.offlineAlarm" required={false} />

      <FieldArray component={renderUserWhitelist} name="configuration.userWhitelist" required={false} />

      <Divider style={{width: '100%'}} />

      <FieldArray component={renderAllowedSourcesForDatasource} name="configuration.allowedSourcesForDatasource" />
      <FieldArray
        component={renderAllowedSourcesForImportingMedia}
        name="configuration.allowedSourcesForImportingMedia"
      />

      <Divider style={{width: '100%'}} />

      <Form.Button type="submit" disabled style={{marginBottom: '20px'}}>
        Save
      </Form.Button>
    </Form>
  );
};

// eslint-disable-next-line import/no-mutable-exports
let MyForm = reduxForm({
  form: 'AccountEdit_Form',
  enableReinitialize: true,
  validate: validator,
})(AccountEdit);

const selector = formValueSelector('AccountEdit_Form');
MyForm = connect((state) => ({
  isTrial: selector(state, 'isTrial'),
  businessLine: selector(state, 'businessLine'),
  type: selector(state, 'type'),
}))(MyForm);
export default MyForm;
