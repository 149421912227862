import React, {Component} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';

export default class PackageSelectorFormInput extends Component {
  state = {
    packages: [
      {key: 'starter', value: 'starter', text: 'Starter'},
      {key: 'business', value: 'business', text: 'Business'},
      {key: 'professional', value: 'professional', text: 'Professional'},
      {key: 'enterprise', value: 'enterprise', text: 'Enterprise'},
    ],
  };

  render() {
    const {required, label, input, disabled} = this.props;

    const {packages} = this.state;

    return (
      <Form.Field required={required} disabled={disabled}>
        <label>{label}</label>
        <Dropdown
          value={input.value}
          placeholder={label}
          search
          selection
          options={packages}
          onChange={(e, {value}) => input.onChange(value)}
        />
      </Form.Field>
    );
  }
}
