import _ from 'lodash';
import React, {Component} from 'react';
import moment from 'moment';
import {Dimmer, Loader} from 'semantic-ui-react';

import API from '../../../lib/api';

class ReportSummary extends Component {
  state = {
    error: null,
    loading: true,
    totalDevices: null,
    usedUnitOfDevices: null,
  };

  componentDidMount() {
    const {accountId, dateRange, data} = this.props;

    if (data) {
      return this.onDataLoad(data);
    }

    const startDate = moment(dateRange.start, 'DD.MM.YYYY');
    const endDate = moment(dateRange.end, 'DD.MM.YYYY');

    const params = {
      accountId,
      startDate: startDate.valueOf(),
      endDate: endDate.endOf('day').valueOf(), // make end date inclusive
    };

    API.getInstance()
      .get(`/device-registration`, {params})
      .then(({data: responseData}) => {
        this.onDataLoad(responseData);
      })
      .catch((err) => {
        this.setState({
          error: err,
          loading: false,
        });
      });
  }

  onDataLoad(data) {
    const {dateRange, onRowDataLoad} = this.props;

    const startDate = moment(dateRange.start, 'DD.MM.YYYY');
    const endDate = moment(dateRange.end, 'DD.MM.YYYY').add(1, 'days');

    const durationOfRange = endDate.diff(startDate, 'days');

    if (onRowDataLoad) onRowDataLoad(data);

    const totalDevices = Object.keys(_.groupBy(data.rows, 'Device.uuid')).length;
    let actualDeviceUsage = 0;
    let usedUnitOfDevices;

    data.rows.forEach((usage) => {
      const leftBoundary = moment.max(moment(usage.adoptedAt), startDate);
      const rightBoundary = usage.dismissedAt ? moment.min(moment(usage.dismissedAt), endDate) : endDate;

      actualDeviceUsage += rightBoundary.diff(leftBoundary, 'days');
    });

    usedUnitOfDevices = actualDeviceUsage / durationOfRange;

    this.setState({
      error: null,
      loading: false,
      totalDevices,
      usedUnitOfDevices: parseFloat(usedUnitOfDevices.toFixed(1), 10),
    });
  }

  render() {
    const {error, loading, totalDevices, usedUnitOfDevices} = this.state;

    if (loading) {
      return (
        <div className="device-report-summary-content loading-spinner">
          <Dimmer active inverted>
            <Loader size="mini" inverted>
              Loading
            </Loader>
          </Dimmer>
        </div>
      );
    }

    if (error) {
      return <div className="device-report-summary-content">Error ({error.message})</div>;
    }

    return (
      <div className="device-report-summary-content">
        {totalDevices}
        <br />
        <span className="dimmed-content">{usedUnitOfDevices}</span>
      </div>
    );
  }
}

export default ReportSummary;
