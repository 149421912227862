import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';

import reducers from './reducers';

/**
 * @class Store
 * @description History and store instance manager for redux.
 */
class Store {
  /**
   * @constructor
   */
  constructor() {
    this.history = createHistory();
    this.store = null;
    this.middlewares = [thunkMiddleware, routerMiddleware(this.history)];
  }

  /**
   * @function getHistory
   * @description Returns history instance.
   * @return {Object} History instance.
   */
  getHistory() {
    return this.history;
  }

  /**
   * @function getInstance
   * @description Creates an instance if not exist.
   * @return {Object} Store instance.
   */
  getInstance() {
    return this.store || this.createInstance();
  }

  /**
   * Creates a store instance.
   * @description Creates an instance if not exists.
   * @param  {Object} initialState
   * @return {Object} Store instance.
   */
  createInstance(initialState = {}) {
    if (this.store) return this.store;

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    /* eslint-disable no-underscore-dangle */
    this.store = createStore(
      reducers,
      initialState,
      composeEnhancers(applyMiddleware(...this.middlewares)),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
    /* eslint-enable */

    return this.store;
  }
}

export default new Store();
