import React, {Component} from 'react';
import {Form, Dropdown} from 'semantic-ui-react';
import API from '../../lib/api';

export default class AccountParentSelector extends Component {
  state = {
    items: [{key: 'reseller-empty', value: null, text: ''}],
  };

  componentDidMount = () => {
    const {currentAccountId} = this.props;
    API.getInstance()
      .get('/account', {params: {limit: 'all'}})
      .then((response) => {
        const accountsAsItems = response.data.rows
          .filter((account) => account.type === 'reseller' && account.id !== currentAccountId)
          .map((account) => ({
            key: account.slug,
            value: account.id,
            text: account.name,
          }));

        this.setState((prevState) => ({
          items: [...prevState.items, ...accountsAsItems],
        }));
      })
      .catch((err) => console.log(err));
  };

  render() {
    const {required, label, input} = this.props;

    const {items} = this.state;

    return (
      <Form.Field required={required}>
        <label>{label}</label>
        <Dropdown
          value={input.value}
          placeholder={label}
          search
          selection
          options={items}
          onChange={(e, {value}) => input.onChange(value)}
        />
      </Form.Field>
    );
  }
}
