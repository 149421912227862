/* eslint-disable  */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {Table, Button, Popup} from 'semantic-ui-react';
import API from '../../lib/api';
import FilteredTable from '../../components/table/filtered-table';

class UserList extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    updatingUsers: [], // Hold userIds for feedback
  };

  clearFailedLoginAttempts = (user, event) => {
    const {history} = this.props;
    this.setState({updatingUsers: [...this.state.updatingUsers, user.id]});

    return API.getInstance()
      .put(`/user/${user.id}`, {consecutiveFailedLoginAttempts: 0})
      .then((response) => {
        history.go(0);
      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => this.setState({updatingUsers: this.state.updatingUsers.filter((u) => u != user.id)}), 1000);
      });
  };

  renderUserListResults() {
    const {history, match, location} = this.props;

    return (
      <div style={{marginTop: '30px', marginBottom: '30px'}}>
        <FilteredTable
          history={history}
          match={match}
          location={location}
          ref={(ref) => (this.apiTableRef = ref)}
          key="user_results_list"
          apiPath="/user"
          searchRoot="/users"
          fields={[
            {
              component: 'input',
              name: 'name',
              label: 'User Name',
              defaultValue: '',
            },
            {
              component: 'input',
              name: 'email',
              label: 'Email',
              defaultValue: '',
            },
          ]}
          headers={[
            {key: 'name', displayName: 'Name', sortable: true},
            {key: 'email', displayName: 'Mail', sortable: true},
            {key: 'phoneNumber', displayName: 'Phone', sortable: false},
            {key: 'lastLogin', displayName: 'Last Login Time', sortable: true},
            {key: 'lastFailedLogin', displayName: 'Last Failed Login Time', sortable: true},
            {key: 'consecutiveFailedLoginAttempts', displayName: 'Failed Login Count', sortable: true},
            {key: 'backofficeAccess', displayName: 'Backoffice Access', sortable: false},
            {key: 'twofactor', displayName: 'Two Factor', sortable: false},
            {key: 'updatedAt', displayName: 'Updated At', sortable: true},
            {key: 'createdAt', displayName: 'Created At', sortable: true},
          ]}
          renderRow={(user) => this.renderUserResult(user)}
          updateOnPropChange
        />
      </div>
    );
  }

  clearFailedLoginAttempts;

  renderUserResult(user) {
    return (
      <Table.Row key={`user_${user.id}`}>
        <Table.Cell>{user.name}</Table.Cell>
        <Table.Cell>{user.email}</Table.Cell>
        <Table.Cell>{user.phoneNumber}</Table.Cell>
        <Table.Cell>{user.lastLogin && moment(user.lastLogin).format('DD/MMM/YYYY HH:mm')}</Table.Cell>
        <Table.Cell>{user.lastFailedLogin && moment(user.lastFailedLogin).format('DD/MMM/YYYY HH:mm')}</Table.Cell>
        <Table.Cell>
          {user.consecutiveFailedLoginAttempts}
          {user.consecutiveFailedLoginAttempts > 0 && (
            <Popup
              content="Clear"
              inverted
              size="mini"
              trigger={
                <Button
                  style={{marginLeft: 10, padding: 7, cursor: 'pointer'}}
                  circular
                  color="blue"
                  icon="remove"
                  onClick={(event) => this.clearFailedLoginAttempts(user, event)}
                  loading={this.state.updatingUsers.includes(user.id)}
                  disabled={this.state.updatingUsers.includes(user.id)}
                />
              }
            />
          )}
        </Table.Cell>
        <Table.Cell>{user.backofficeAccess.toString()}</Table.Cell>
        <Table.Cell>{user.twofactor}</Table.Cell>
        <Table.Cell>{moment(user.updatedAt).format('DD/MMM/YYYY HH:mm')}</Table.Cell>
        <Table.Cell>{moment(user.createdAt).format('DD/MMM/YYYY')}</Table.Cell>
      </Table.Row>
    );
  }

  render() {
    return (
      <div className="view">
        <ul className="post-bar">
          <h1>Users</h1>
        </ul>
        {this.renderUserListResults()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
