import React, {Component} from 'react';
import {Input, Form, Divider, Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SmsVerification from '../../components/smsverification';
import logo from '../../images/logo-black.png';
import * as AuthenticationActions from '../../actions/authentication';
import * as Fingerprint from '../../lib/fingerprint';

class Login extends Component {
  static propTypes = {
    authenticationActions: PropTypes.object.isRequired,
  };

  state = {
    email: '',
    password: '',
    loading: false,
    isModalActive: false,
    fingerprintHash: null,
  };

  containerStyle = {
    margin: 'auto',
    maxWidth: '400px',
    padding: '100px 10px',
  };

  componentDidMount() {
    Fingerprint.autoDetect().then((hash) => {
      this.setState({
        fingerprintHash: hash,
      });
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({loading: true});

    const {authenticationActions} = this.props;
    const {email, password, fingerprintHash} = this.state;

    authenticationActions.login(email, password, fingerprintHash).then((response) => {
      if (!response) {
        return this.setState({loading: false});
      }

      this.setState({loading: false, isModalActive: true});
      console.log(`Token life time is ${response.expire}`);
    });
  }

  render() {
    const {email, password, loading, isModalActive} = this.state;

    return (
      <div className="login-view" style={this.containerStyle}>
        <Form onSubmit={(value) => this.onSubmit(value)}>
          <div className="logo-container">
            <img src={logo} className="App-logo" alt="OMMA Logo" />
            <div className="product-label">Backoffice</div>
          </div>
          <Divider />
          <Form.Field>
            <Input
              type="text"
              placeholder="Email"
              value={email}
              onChange={({target: {value}}) => this.setState({email: value})}
            />
          </Form.Field>
          <Form.Field>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={({target: {value}}) => this.setState({password: value})}
            />
          </Form.Field>
          <Button type="submit" loading={loading}>
            Login
          </Button>
        </Form>
        <SmsVerification
          email={email}
          authenticationActions={this.props.authenticationActions}
          isActive={isModalActive}
          onClose={(value) => {
            this.setState({isModalActive: false});
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  authenticationActions: bindActionCreators(AuthenticationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
