import React, {Component} from 'react';
import {Table, Label, Grid, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import moment from 'moment';
import FilteredTable from '../../components/table/filtered-table';

const ISFREE_TYPES = [
  {key: '*', value: '*', text: 'All'},
  {key: 'true', value: 'true', text: 'Free'},
  {key: 'false', value: 'false', text: 'Paid'},
];
const VISIBILITY_TYPES = [
  {key: '*', value: '*', text: 'All'},
  {key: 'true', value: 'true', text: 'Public'},
  {key: 'false', value: 'false', text: 'Private'},
];

class AddonWidgetManagement extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  renderRow(item, type) {
    return (
      <Table.Row key={item.key} onClick={() => this.onRowClicked(item, type)}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>
          <Label tag color={item.isFree ? 'green' : 'violet'}>
            {item.isFree ? 'Free' : 'Paid'}
          </Label>
        </Table.Cell>
        <Table.Cell>{item.isPublic ? 'Public' : 'Private'}</Table.Cell>
        <Table.Cell>{moment(item.updatedAt).calendar()}</Table.Cell>
        <Table.Cell>{moment(item.createdAt).calendar()}</Table.Cell>
      </Table.Row>
    );
  }

  onRowClicked(item, type) {
    const {history} = this.props;
    history.push(`/${type}/${item.id}`);
  }

  render() {
    const {history, match, location} = this.props;

    const filters = [
      {
        component: 'input',
        name: 'name',
        label: 'Name',
        defaultValue: '',
      },
      {
        component: 'dropdown',
        name: 'isFree',
        label: 'Type',
        defaultValue: ['*'],
        parse: (type) => {
          const parsed = type
            .split(',')
            .map((s) => s.trim())
            .filter((s) => !!s);
          return parsed.length > 0 ? parsed : '*';
        },
        props: {selection: true, multiple: true, options: ISFREE_TYPES},
      },
      {
        component: 'dropdown',
        name: 'isPublic',
        label: 'Visibility',
        defaultValue: ['*'],
        parse: (type) => {
          const parsed = type
            .split(',')
            .map((s) => s.trim())
            .filter((s) => !!s);
          return parsed.length > 0 ? parsed : '*';
        },
        props: {selection: true, multiple: true, options: VISIBILITY_TYPES},
      },
    ];

    const getColumns = (type) => [
      {key: 'name', displayName: `${type} Name`, sortable: true},
      {key: 'isFree', displayName: 'Type', sortable: true},
      {key: 'isPublic', displayName: 'Visibility', sortable: true},
      {key: 'updatedAt', displayName: 'Updated At', sortable: true},
      {key: 'createdAt', displayName: 'Created At', sortable: true},
    ];

    return (
      <div className="view container">
        <h1>Addon & Widget Management</h1>
        <Grid.Column style={{borderBottom: '2px solid #ddd', marginBottom: 20}} />
        <Grid divided="vertically">
          <Grid.Column rows={2}>
            <Grid.Row>
              <Grid style={{marginTop: 20, marginBottom: 20}}>
                <Grid style={{flex: 1}}>
                  <h2>Widgets</h2>
                </Grid>
                <Grid style={{margin: 0}}>
                  <Button color="primary" onClick={() => history.push('/widget/new')}>
                    Create Widget
                  </Button>
                </Grid>
              </Grid>
              <FilteredTable
                history={history}
                match={match}
                location={location}
                itemsPerPage={10}
                defaultSort="name"
                apiPath="/widget"
                searchRoot="/addon-widget"
                fields={filters}
                headers={getColumns('Widget')}
                renderRow={(item) => this.renderRow(item, 'widget')}
                onTableDataFetch={this.onTableData}
              />
            </Grid.Row>
            <Grid.Row>
              <Grid style={{marginTop: 20, marginBottom: 20}}>
                <Grid style={{flex: 1}}>
                  <h2>Addons</h2>
                </Grid>
                <Grid style={{margin: 0}}>
                  <Button color="primary" onClick={() => history.push('/addon/new')}>
                    Create Addon
                  </Button>
                </Grid>
              </Grid>
              <FilteredTable
                history={history}
                match={match}
                location={location}
                itemsPerPage={10}
                defaultSort="name"
                apiPath="/addon"
                searchRoot="/addon-widget"
                fields={filters}
                headers={getColumns('Addon')}
                renderRow={(item) => this.renderRow(item, 'addon')}
                onTableDataFetch={this.onTableData}
                hideFilters
              />
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddonWidgetManagement);
