import axios from 'axios';
import urljoin from 'url-join';
import queryString from 'query-string';
import config from '../config';

class API {
  constructor() {
    this.instance = axios.create({
      baseURL: urljoin(config.API_PATH, config.API_VERSION),
      timeout: 30000,
      responseType: 'json',
      withCredentials: true,
      paramsSerializer: (params) => queryString.stringify(params, {arrayFormat: 'comma'}),
    });
  }

  getInstance() {
    return this.instance;
  }
}

export default new API();
