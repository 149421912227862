import React, {Component} from 'react';
import {DateTimeInput} from 'semantic-ui-calendar-react';
import {Form} from 'semantic-ui-react';
const moment = require('moment');

export default class TrialPeriodEndsAt extends Component {
  state = {};

  componentDidMount() {
    const {input} = this.props;

    if (!input.value) {
      const defaultTrialEndDate = moment().add(30, 'days').toISOString();
      input.onChange(defaultTrialEndDate);
    }
  }

  render() {
    const {required, label, input} = this.props;
    const formattedValue = moment(input.value).format('DD/MM/YYYY HH:mm');

    return (
      <Form.Field required={required}>
        <label>{label}</label>
        <DateTimeInput
          value={formattedValue}
          dateFormat="DD/MM/YYYY"
          timeFormat="24"
          closable
          onChange={(e, {value: formattedValue}) => {
            const parsedValue = moment(formattedValue, 'DD/MM/YYYY HH:mm');
            input.onChange(parsedValue.toISOString());
          }}
        />
      </Form.Field>
    );
  }
}
