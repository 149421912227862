import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as AuthenticationActions from '../../actions/authentication';

import Home from '../home';
import HelloList from '../hello/list';
import AccountList from '../account/list';
import AccountEdit from '../account/edit';
import AccountAddNewUser from '../account/addnewuser';
import AccountAddExistingUser from '../account/addexistinguser';
import UserList from '../user/list';
import Audit from '../audit';
import DeviceUsage from '../device-usage/list';
import AddonUsage from '../addon-usage';
import AddonWidgetManagement from '../addon-widget/list';
import Addon from '../addon-widget/addon-edit';
import Widget from '../addon-widget/widget-edit';

import Sidebar from '../../components/sidebar';

const Authenticated = ({history, authenticationActions}) => (
  <div className="pusher">
    <div className="full height">
      <Sidebar history={history} authenticationActions={authenticationActions} />
      <div className="article">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/accounts/:currentPage?" exact component={AccountList} />
          <Route path="/account/new" exact component={AccountEdit} />
          <Route path="/account/:id(\d+)" exact component={AccountEdit} />
          <Route path="/account/:id/:tabName" exact component={AccountEdit} />
          <Route path="/account/:accountId/user/new" exact component={AccountAddNewUser} />
          <Route path="/account/:accountId/user/existing" exact component={AccountAddExistingUser} />
          <Route path="/users" exact component={UserList} />
          <Route path="/hello" exact component={HelloList} />
          <Route path="/audit" exact component={Audit} />
          <Route path="/device-usage" exact component={DeviceUsage} />
          <Route path="/addon-usage" exact component={AddonUsage} />
          <Route path="/addon-widget" exact component={AddonWidgetManagement} />
          <Route path="/addon/:id" exact component={Addon} />
          <Route path="/widget/:id" exact component={Widget} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  authenticationActions: bindActionCreators(AuthenticationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
