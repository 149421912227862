import React from 'react';
import {Form} from 'semantic-ui-react';
import {InputField} from 'react-semantic-redux-form';
import {Field, reduxForm} from 'redux-form';

const validate = (values) => {
  const errors = {};
  if (Number.isNaN(values.contentId)) {
    errors.contentId = 'Invalid content ID';
  }
  return errors;
};

const ImportContentForm = ({handleSubmit, pristine, submitting, submitSucceeded}) => (
  <>
    <Form onSubmit={handleSubmit}>
      <Form.Group inline>
        <Field component={InputField} name="contentId" placeholder="Content ID" label="Content ID" />

        <Form.Button type="submit" disabled={pristine || submitting} content="Import" />
      </Form.Group>
    </Form>
  </>
);

export default reduxForm({
  form: 'ImportContent_Form',
  validate,
  enableReinitialize: true,
})(ImportContentForm);
