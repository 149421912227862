import React, {Component} from 'react';
import get from 'lodash/get';
import {InputField, TextAreaField, CheckboxField} from 'react-semantic-redux-form';
import {Field, FieldArray, reduxForm, formValueSelector} from 'redux-form';
import {Form, Button, Grid, Image, Confirm} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {toast} from 'react-semantic-toasts';
import config from '../../config';
import API from '../../lib/api';
const APIInstance = API.getInstance();

const successToast = (title, description) =>
  toast({
    type: 'success',
    icon: 'check',
    title,
    description,
  });

const errorToast = (title, description) =>
  toast({
    type: 'error',
    icon: 'exclamation',
    title,
    description,
  });

const WidgetForm = (props) => {
  const {handleSubmit, submitting} = props;
  const {key, versions, isNew} = props.initialValues;

  const renderVersionField = ({fields, input, index, meta: {touched, error}}) => (
    <div style={{marginBottom: 10}}>
      <div style={{marginBottom: 5}}>
        <input
          {...input}
          type="text"
          placeholder="Version"
          style={{width: '85%', marginRight: 5}}
          disabled={versions.includes(input.value)}
        />
        {!versions.includes(input.value) && (
          <Button circular type="button" icon="remove" size="small" onClick={() => fields.remove(index)} />
        )}
      </div>
      {touched && error && <span style={{color: '#fa0500', fontWeight: 'bold'}}>{error}</span>}
    </div>
  );

  const renderVersions = ({fields}) => (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <b style={{marginBottom: 5}}>Versions:</b>
      <div>
        {fields.map((version, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <Field name={version} component={renderVersionField} fields={fields} index={index} required />
          </div>
        ))}
        <Button type="button" size="small" onClick={() => fields.push()}>
          Add Version
        </Button>
      </div>
    </div>
  );

  return (
    <Form style={{marginTop: 10}} onSubmit={handleSubmit}>
      <Grid divided="vertically">
        <Grid.Row columns={1}>
          <Grid.Column style={{borderBottom: '2px solid #ddd'}} />
          {!isNew && (
            <Grid.Column>
              <h5>Image</h5>
              <Image src={`${config.CDN_PATH}/widget/${key}/${versions[versions.length - 1]}/logo.png`} size="small" />
            </Grid.Column>
          )}
          {isNew && (
            <Grid.Column>
              <Field component={InputField} name="key" placeholder="Key" label="Key" required />
            </Grid.Column>
          )}
          <Grid.Column>
            <Field component={InputField} name="name" placeholder="Name" label="Name" required />
          </Grid.Column>
          <Grid.Column>
            <Field
              component={TextAreaField}
              name="description"
              placeholder="Description"
              label="Description"
              required
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column>
            <FieldArray component={renderVersions} name="versions" />
          </Grid.Column>
          <Grid.Column style={{marginBottom: 10, marginTop: 10, borderLeft: '1px solid #ccc'}}>
            <b style={{display: 'flex', marginBottom: 20}}>Type/Visibility:</b>
            <Field component={CheckboxField} name="isFree" placeholder="Free" label="Free" toggle />
            <Field component={CheckboxField} name="isPublic" placeholder="Public" label="Public" toggle />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button type="submit" disabled={submitting} color="green">
              {isNew ? 'Create' : 'Save'}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};
class WidgetEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNew: props.match.params.id === 'new',
      key: props.match.params.id === 'new' ? 'omma.widget.' : '',
      name: '',
      description: '',
      versions: [],
      isFree: false,
      isPublic: false,
      showDeleteConfirmation: false,
    };
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    const {isNew} = this.state;

    if (!isNew) {
      try {
        const result = await APIInstance.get(`/widget/${this.props.match.params.id}`);
        const {key, name, description, versions, isFree, isPublic} = result.data;
        this.setState({key, name, description, versions, isFree, isPublic});
      } catch (error) {
        return errorToast('Error', get(error, 'response.data.error'));
      }
    }
  }

  async save(rawWidget) {
    const {isNew} = this.state;
    if (!rawWidget.key.startsWith('omma.widget.')) {
      return errorToast('Not Valid!', 'Widget key must start with omma.widget.');
    }
    if (isNew) {
      try {
        const result = await APIInstance.post('/widget/', rawWidget);
        successToast('Saved!', 'Widget succesfully created.');

        const {history} = this.props;
        history.push(`/widget/${result.data.id}`);
      } catch (error) {
        return errorToast('Error', get(error, 'response.data.error'));
      }
    } else {
      try {
        await APIInstance.put(`/widget/${this.props.match.params.id}`, rawWidget);
        return successToast('Saved!', 'Widget succesfully saved.');
      } catch (error) {
        return errorToast('Error', get(error, 'response.data.error'));
      }
    }
  }

  async deleteSelectedExtension() {
    try {
      await API.getInstance().delete(`/widget/${this.props.match.params.id}`);
      successToast('Deleted', 'Widget Deleted');
      const {history} = this.props;
      history.push('/widget-widget');
    } catch (error) {
      return errorToast('Error', get(error, 'response.data.error'));
    }
  }

  render() {
    const {key, name, description, versions, isFree, isPublic, isNew, showDeleteConfirmation} = this.state;
    return (
      <div className="view">
        <h1>{isNew ? 'Create Widget' : name}</h1>
        <span style={{color: 'silver'}}>{isNew ? '' : key}</span>

        <MyForm
          initialValues={{key, name, description, versions, isFree, isPublic, isNew}}
          onSubmit={(values) => this.save(values)}
        />
        {!isNew && (
          <Button color="negative" onClick={() => this.setState({showDeleteConfirmation: true})}>
            Delete
          </Button>
        )}

        <Confirm
          open={showDeleteConfirmation}
          content={`Are you sure you want to delete the ${name} widget?`}
          onConfirm={() => this.deleteSelectedExtension()}
          onCancel={() => this.setState({showDeleteConfirmation: false})}
        />
      </div>
    );
  }
}

// eslint-disable-next-line import/no-mutable-exports
let MyForm = reduxForm({
  form: 'WidgetEdit_Form',
  enableReinitialize: true,
})(WidgetForm);

const selector = formValueSelector('WidgetEdit_Form');
MyForm = connect((state) => ({package: selector(state, 'package')}))(MyForm);

export default WidgetEdit;
