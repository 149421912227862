import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Confirm} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import API from '../../lib/api';
import * as roleActions from '../../actions/role';

class RoleSelector extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    roleId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    onRoleChanged: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      roles: props.role,
      confirmationContent: '',
      showConfirmation: false,
      nextValue: null,
      value: null,
      blockUI: false,
    };
  }

  update(roleId) {
    const {userId, accountId} = this.props;

    return new Promise((resolve, reject) => {
      this.setState({blockUI: true}, () => {
        API.getInstance()
          .put(`/user/${userId}/account/${accountId}/role/${roleId}`)
          .then((response) => {
            this.setState({blockUI: false});
            if (this.props.onRoleChanged) {
              this.props.onRoleChanged(_.pick(this.props, ['accountId', 'roleId', 'userId']));
            }
            resolve();
          })
          .catch(reject);
      });
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (!this.props.role.length) this.props.roleActions.getAll();

    this.setState({value: this.props.roleId});
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEqual(nextProps.role, this.state.roles)) return;

    this.setState({roles: nextProps.role});
  }

  onChanged(value) {
    this.setState((prevState) => {
      const currentRole = _.find(prevState.roles, {key: prevState.value});
      const nextRole = _.find(prevState.roles, {key: value});
      return {
        showConfirmation: true,
        nextValue: value,
        confirmationContent: `Are you sure you want to change ${this.props.userName}‘s
        role from ${currentRole.text} to ${nextRole.text}?`,
      };
    });
  }

  confirmationCanceled() {
    this.setState({showConfirmation: false});
  }

  confirmationApproved() {
    const {nextValue} = this.state;
    this.update(nextValue)
      .then(() => {
        this.setState({
          showConfirmation: false,
          value: nextValue,
          nextValue: null,
        });
      })
      .catch(() => {
        this.setState({
          showConfirmation: false,
          nextValue: null,
        });
      });
  }

  render() {
    const {blockUI = false, roles, showConfirmation, value, confirmationContent} = this.state;

    return (
      <div>
        <Dropdown
          value={value}
          placeholder="Role"
          search
          selection
          options={roles}
          onChange={(e, {value: formValue}) => this.onChanged(formValue)}
          disabled={blockUI}
        />
        <Confirm
          open={showConfirmation}
          content={confirmationContent}
          onCancel={() => this.confirmationCanceled()}
          onConfirm={() => this.confirmationApproved()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  roleActions: bindActionCreators(roleActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleSelector);
