import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {toast} from 'react-semantic-toasts';

import {
  Tab,
  Table,
  Icon,
  Button,
  Container,
  Confirm,
  Statistic,
  Menu,
  Label,
  Modal,
  Header,
  Message,
  Popup,
} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import Mosaic from '../../../components/mosaic';
import Addons from '../../../components/addons';
import Widgets from '../../../components/widgets';
import APITable from '../../../components/table';
import RoleSelector from '../../../components/roleselector';
import TwoFactorSelector from '../../../components/twofactorselector';
import config from '../../../config';
import * as DeviceActions from '../../../actions/device';
import * as AccountActions from '../../../actions/account';
import * as UserActions from '../../../actions/user';
import * as AddonActions from '../../../actions/addon';
import * as WidgetActions from '../../../actions/widget';
import * as RoleActions from '../../../actions/role';

import Form from './form';
import ImportContentForm from './import-content-form';
import InviteUserForm from './invite-user-form';

import './style.css';

const AccountEditTabType = {
  ACCOUNT: 'account',
  USER_LIST: 'userList',
  USER_INVITATION_LIST: 'userInvitationList',
  SUB_ACCOUNT: 'subAccount',
  DEVICE_LIST: 'deviceList',
  ADD_ON_LIST: 'addOnList',
  WIDGET_LIST: 'widgetList',
  REPORT: 'report',
  ACTIONS: 'actions',
  VQ: 'VQ',
};

const AccountEditRoutePaths = {
  edit: AccountEditTabType.ACCOUNT,
  users: AccountEditTabType.USER_LIST,
  userInvitations: AccountEditTabType.USER_INVITATION_LIST,
  subAccounts: AccountEditTabType.SUB_ACCOUNT,
  devices: AccountEditTabType.DEVICE_LIST,
  addons: AccountEditTabType.ADD_ON_LIST,
  widgets: AccountEditTabType.WIDGET_LIST,
  report: AccountEditTabType.REPORT,
  actions: AccountEditTabType.ACTIONS,
  vq: AccountEditTabType.VQ,
};

class AccountEdit extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    accountActions: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      raw: {
        id: null,
        name: null,
        package: null,
        businessLine: 'signage',
        status: 'active',
        type: 'customer',
        parentId: null,
        limitations: {
          device: '65536',
        },
        notes: '',
        configuration: {
          defaultDeviceAnalytics: false,
          skipAppUpdateOnAdoption: false,
          userWhitelist: [],
          allowedSourcesForImportingMedia: ['*'],
          allowedSourcesForDatasource: ['*'],
          offlineAlarm: {
            enabled: false,
            maxOfflineTime: 900000, // in ms (15 minutes)
            alarmGroupingType: 'device',
            emailTo: [],
          },
          crossoverEnabledByDefault: false,
          restartOnFreeze: false,
        },
        report: {
          userCount: 0,
          userInvitationCount: 0,
          addonCount: 0,
          widgetCount: 0,
        },
        trialPeriodEndsAt: null,
      },
      importJobs: [],
      vqImportJobs: [],
      isNew: props.match.url === '/account/new',
      confirmationContent: '',
      showConfirmation: false,
      selectedAddonToAdd: null,
      selectedWidgetToAdd: null,
      showMessage: false,
    };
  }

  panes = [];

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {isNew} = this.state;
    const {match, accountActions, roleActions} = this.props;

    if (!isNew) {
      accountActions.get(match.params.id);
      accountActions.listContentImportJobs(match.params.id);
      accountActions.listContentImportJobs(match.params.id, {isVQ: true});
    }

    roleActions.getAll().then((roles) => this.setState({roles}));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {raw, isNew} = nextState;

    if (!raw) return;

    const panes = [
      {
        menuItem: 'Account',
        render: () => this.renderAccountInfo(),
        type: AccountEditTabType.ACCOUNT,
      },
    ];

    if (!isNew) {
      panes.push({
        menuItem: (
          <Menu.Item key="users">
            Users<Label>{raw.report.userCount}</Label>
          </Menu.Item>
        ),
        render: () => this.renderAccountUsers(),
        type: AccountEditTabType.USER_LIST,
      });

      panes.push({
        menuItem: (
          <Menu.Item key="sub-accounts">
            Sub-Accounts<Label>{raw.report.subAccountCount}</Label>
          </Menu.Item>
        ),
        render: () => this.renderSubAccounts(),
        type: AccountEditTabType.SUB_ACCOUNT,
      });

      panes.push({
        menuItem: (
          <Menu.Item key="user-invitations">
            User Invitations<Label>{raw.report.userInvitationCount}</Label>
          </Menu.Item>
        ),
        render: () => this.renderAccountUserInvitations(),
        type: AccountEditTabType.USER_INVITATION_LIST,
      });

      panes.push({
        menuItem: (
          <Menu.Item key="devices">
            Devices<Label>{raw.report.deviceCount}</Label>
          </Menu.Item>
        ),
        render: () => this.renderAccountDevices(),
        type: AccountEditTabType.DEVICE_LIST,
      });

      panes.push({
        menuItem: (
          <Menu.Item key="addons">
            Add-ons<Label>{raw.report.addonCount}</Label>
          </Menu.Item>
        ),
        render: () => this.renderAccountAddons(),
        type: AccountEditTabType.ADD_ON_LIST,
      });

      panes.push({
        menuItem: (
          <Menu.Item key="widgets">
            Widgets<Label>{raw.report.widgetCount}</Label>
          </Menu.Item>
        ),
        render: () => this.renderAccountWidgets(),
        type: AccountEditTabType.WIDGET_LIST,
      });

      panes.push({
        menuItem: 'Report',
        render: () => this.renderAccountReport(),
        type: AccountEditTabType.REPORT,
      });

      panes.push({
        menuItem: 'Actions',
        render: () => this.renderAccountActions(),
        type: AccountEditTabType.ACTIONS,
      });

      panes.push({
        menuItem: 'VQ',
        render: () => this.renderVQActions(),
        type: AccountEditTabType.VQ,
      });
    }

    this.panes = panes;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const idFromProps = _.get(nextProps, 'match.params.id');
    const idFromState = _.get(this.state, 'raw.id');

    if (!idFromProps || idFromProps === 'new') {
      this.setState({
        isNew: true,
      });
    } else if (idFromProps !== idFromState) {
      const account = nextProps.account[idFromProps];
      this.setState({raw: account});
    }
  }

  onEditFormSubmit(values) {
    if (values.businessLine === 'signage' && values.type === 'customer' && values.parentId === null) {
      return toast({
        type: 'error',
        icon: 'exclamation',
        title: 'Parent account is required',
        description: 'Please select a parent account',
      });
    }

    if (values.type === 'customer' && (values.package === null || values.package === undefined)) {
      return toast({
        type: 'error',
        icon: 'exclamation',
        title: 'Package is required',
        description: 'Please select a package',
      });
    }

    if (values.isTrial && (values.trialPeriodEndsAt === null || values.trialPeriodEndsAt === undefined)) {
      return toast({
        type: 'error',
        icon: 'exclamation',
        title: 'Trial period end date is required',
        description: 'Please enter a valid Account Trial period end Date',
      });
    }

    const {accountActions, history} = this.props;
    const {isNew} = this.state;

    if (isNew) {
      const data = _.pick(values, [
        'name',
        'businessLine',
        'package',
        'type',
        'parentId',
        'status',
        'limitations.device',
        'notes',
        'configuration',
        'isTrial',
        'trialPeriodEndsAt',
      ]);
      if (data.isTrial === false) {
        delete data.trialPeriodEndsAt;
      }
      if (data.type !== 'customer') {
        delete data.package;
      }

      return accountActions.create(data).then((isValid) => isValid && history.push('/accounts'));
    }

    const data = _.pick(values, [
      'name',
      'businessLine',
      'package',
      'type',
      'parentId',
      'status',
      'limitations.device',
      'notes',
      'configuration',
      'isTrial',
      'trialPeriodEndsAt',
    ]);
    if (data.isTrial === false) {
      delete data.trialPeriodEndsAt;
    }
    if (data.type !== 'customer') {
      delete data.package;
    }

    return accountActions.update(values.id, data).then((isValid) => isValid && history.push('/accounts'));
  }

  onInviteUserFormSubmit(values) {
    const {userActions} = this.props;
    const accountId = this.state.raw.id;
    const {email, roleId} = values;

    return userActions.inviteUserToAccount(email, roleId, accountId).then((isValid) => {
      if (isValid) {
        this.changeActiveTab(AccountEditTabType.USER_INVITATION_LIST);
        this.setState({showModal: false});
        return this.apiTableRef.fetch();
      }
    });
  }

  onAccountUserRowClicked(item) {
    const {history} = this.props;
    history.push(`/user/${item.UserId}`);
  }

  onUserRemoveClick(userToBeRemoved, e) {
    this.setState({
      userToBeRemoved,
      showConfirmation: true,
      confirmationContent: `Are you sure to remove ${userToBeRemoved.name} (${userToBeRemoved.email}) from this account?`,
    });
    e.stopPropagation();
  }

  onRenewInvitationClick(invitationToResend, e) {
    e.stopPropagation();

    this.props.userActions.renewInvitation(invitationToResend.id).then((isValid) => {
      if (isValid) {
        this.setState({showMessage: true, messageHeader: 'Invitation renewed.', messageColor: 'green'});

        setTimeout(() => {
          this.setState({showMessage: false, messageHeader: ''});
        }, 3000);

        this.apiTableRef.fetch();
      }
    });
  }

  onInvitationRemoveClick(invitationToBeRemoved, e) {
    e.stopPropagation();

    this.setState({
      invitationToBeRemoved,
      showConfirmation: true,
      confirmationContent: `Are you sure to remove this invitation to "${invitationToBeRemoved.email}" address?`,
    });
  }

  onAddonRemoveClick(e, addonToBeRemoved) {
    this.setState({
      addonToBeRemoved,
      showConfirmation: true,
      confirmationContent: `Are you sure to remove ${addonToBeRemoved.name} from this account?`,
    });
    e.stopPropagation();
  }

  onWidgetRemoveClick(e, widgetToBeRemoved) {
    this.setState({
      widgetToBeRemoved,
      showConfirmation: true,
      confirmationContent: `Are you sure to remove ${widgetToBeRemoved.name} from this account?`,
    });
    e.stopPropagation();
  }

  getImportJobs() {
    const {accountActions} = this.props;
    const accountId = this.state.raw.id;

    accountActions.listContentImportJobs(accountId).then((response) => {
      if (!response.some((job) => job.state === 'working')) return this.setState({importJobs: response});

      setTimeout(() => {
        this.getImportJobs();
      }, 1000);
    });
  }

  onImportContentFormSubmit(values) {
    const {accountActions} = this.props;
    const accountId = this.state.raw.id;

    return accountActions.importContent(accountId, values.contentId).then(() => this.getImportJobs());
  }

  getVQImportJobs() {
    const {accountActions} = this.props;
    const accountId = this.state.raw.id;

    accountActions.listContentImportJobs(accountId, {isVQ: true}).then((response) => {
      if (!response.some((job) => job.state === 'working')) return this.setState({vqImportJobs: response});

      setTimeout(() => {
        this.getVQImportJobs();
      }, 1000);
    });
  }

  onImportVQContentFormSubmit(values) {
    const {accountActions} = this.props;
    const accountId = this.state.raw.id;

    return accountActions.importContent(accountId, values.contentId, {isVQ: true}).then(() => this.getVQImportJobs());
  }

  confirmationCanceled() {
    this.setState({
      showConfirmation: false,
      userToBeRemoved: null,
      addonToBeRemoved: null,
      widgetToBeRemoved: null,
      invitationToBeRemoved: null,
    });
  }

  confirmationApproved() {
    if (this.state.userToBeRemoved) {
      this.props.userActions
        .removeFromAccount(this.state.userToBeRemoved.UserId, this.state.raw.id)
        .then(() => {
          this.apiTableRef.fetch();
          this.confirmationCanceled();
        })
        .catch(() => {
          this.confirmationCanceled();
        });
    }

    if (this.state.invitationToBeRemoved) {
      this.props.userActions
        .deleteInvitation(this.state.invitationToBeRemoved.id)
        .then(() => {
          this.apiTableRef.fetch();
          this.confirmationCanceled();
        })
        .catch(() => {
          this.confirmationCanceled();
        });
    }

    if (this.state.addonToBeRemoved) {
      this.props.addonActions
        .removeFromAccount(this.state.addonToBeRemoved.id, this.state.raw.id)
        .then(() => {
          this.apiTableRef.fetch();
          this.confirmationCanceled();
        })
        .catch(() => {
          this.confirmationCanceled();
        });
    }

    if (this.state.widgetToBeRemoved) {
      this.props.widgetActions
        .removeFromAccount(this.state.widgetToBeRemoved.id, this.state.raw.id)
        .then(() => {
          this.apiTableRef.fetch();
          this.confirmationCanceled();
        })
        .catch(() => {
          this.confirmationCanceled();
        });
    }
  }

  renderAccountUserRow(item) {
    return (
      <Table.Row key={`${item.UserId}-${item.RoleId}`}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.email}</Table.Cell>
        <Table.Cell>
          <RoleSelector
            accountId={this.state.raw.id}
            userName={item.name}
            roleId={item.RoleId}
            userId={item.UserId}
            onRoleChanged={() => this.apiTableRef.fetch()}
          />
        </Table.Cell>
        <Table.Cell>
          <TwoFactorSelector
            userId={item.UserId}
            userName={item.name}
            value={item.twofactor}
            onTwoFactorChanged={() => this.apiTableRef.fetch()}
          />
        </Table.Cell>
        <Table.Cell>{moment(item.updatedAt).calendar()}</Table.Cell>
        <Table.Cell>{moment(item.createdAt).calendar()}</Table.Cell>
        <Table.Cell>
          <Button icon color="red" onClick={(event) => this.onUserRemoveClick(item, event)}>
            <Icon name="trash" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }

  renderAccountUserInvitationRow(item) {
    let isExpired = new Date().getTime() > new Date(item.expireDate).getTime();

    return (
      <Table.Row key={item.id} style={{color: isExpired ? '#ff0000' : 'inherit'}}>
        <Table.Cell>
          {item.email}&nbsp;
          {isExpired && (
            <Label color="red" size="tiny" horizontal>
              Expired
            </Label>
          )}
        </Table.Cell>
        <Table.Cell>{this.state.roles.find((role) => role.id === item.roleId).name}</Table.Cell>
        <Table.Cell>{moment(item.updatedAt).calendar()}</Table.Cell>
        <Table.Cell>
          <Popup
            content="Renew Invitation"
            trigger={
              <Button icon="refresh" color="blue" onClick={(event) => this.onRenewInvitationClick(item, event)} />
            }
          />

          <Popup
            content="Delete Invitation"
            trigger={<Button icon="trash" color="red" onClick={(event) => this.onInvitationRemoveClick(item, event)} />}
          />
        </Table.Cell>
      </Table.Row>
    );
  }

  renderAccountDeviceRow(item) {
    const screenshot = item.isOnline ? (
      <img
        style={{maxWidth: '100px'}}
        src={`${config.API_PATH}/${config.API_VERSION}/device/${item.id}/screenshot?width=100&ts=${Date.now()}`}
        alt={`${item.name}`}
      />
    ) : (
      'offline'
    );

    return (
      <Table.Row key={`device_${item.id}_${item.uuid}`}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>
          {item.isOnline ? (
            <Icon size="large" color="green" name="circle" />
          ) : (
            <Icon size="large" color="red" name="circle outline" />
          )}
        </Table.Cell>
        <Table.Cell>
          {item.isInDevMode ? (
            <Icon size="large" color="green" name="check" />
          ) : (
            <Icon size="large" color="red" name="close" />
          )}
        </Table.Cell>
        <Table.Cell>
          {item.appVersion}
          <br />
          {item.baseAppVersion}
        </Table.Cell>
        <Table.Cell>{item.connection}</Table.Cell>
        <Table.Cell>
          {item.manufacturer}
          <br />
          {item.model}
          <br />
          {item.os}
        </Table.Cell>
        <Table.Cell>{item.Shop.name}</Table.Cell>
        <Table.Cell>{moment(item.lastSeenAt).calendar()}</Table.Cell>
        <Table.Cell>{moment(item.updatedAt).calendar()}</Table.Cell>
        <Table.Cell>{moment(item.createdAt).calendar()}</Table.Cell>
        <Table.Cell>{screenshot}</Table.Cell>
      </Table.Row>
    );
  }

  renderAccountAddonRow(item) {
    let type = {name: 'Paid', color: 'blue'};
    let purchasedAt = moment(item.purchasedAt).calendar();

    if (item.isFree) type = {name: 'Free', color: 'green'};
    if (item.isFree && item.isPublic) purchasedAt = `-`;

    return (
      <Table.Row key={`addon_${item.id}`}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell collapsing>
          <Label basic color={type.color}>
            {type.name}
          </Label>
        </Table.Cell>
        <Table.Cell collapsing>{purchasedAt}</Table.Cell>
        <Table.Cell collapsing>
          <Button
            icon
            color="red"
            onClick={(event) => this.onAddonRemoveClick(event, item)}
            disabled={item.isFree && item.isPublic}
          >
            <Icon name="trash" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }

  renderAccountWidgetRow(item) {
    let type = {name: 'Paid', color: 'blue'};
    let purchasedAt = moment(item.purchasedAt).calendar();

    if (item.isFree) type = {name: 'Free', color: 'green'};
    if (item.isFree && item.isPublic) purchasedAt = `-`;

    return (
      <Table.Row key={`widget_${item.id}`}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell collapsing>
          <Label basic color={type.color}>
            {type.name}
          </Label>
        </Table.Cell>
        <Table.Cell collapsing>{purchasedAt}</Table.Cell>
        <Table.Cell collapsing>
          <Button
            icon
            color="red"
            onClick={(event) => this.onWidgetRemoveClick(event, item)}
            disabled={item.isFree && item.isPublic}
          >
            <Icon name="trash" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }

  renderAccountInfo() {
    const {raw} = this.state;

    return (
      <div className="two-column-form-container">
        <Form initialValues={raw} onSubmit={(values) => this.onEditFormSubmit(values)} />
      </div>
    );
  }

  renderAccountUsers() {
    const {raw} = this.state;
    const {history} = this.props;

    return (
      <div>
        <APITable
          key="users_list"
          ref={(ref) => {
            this.apiTableRef = ref;
          }}
          apiPath={`/account/${raw.id}/user`}
          headers={[
            {key: 'name', displayName: 'Name', sortable: false},
            {key: 'email', displayName: 'Email', sortable: false},
            {key: 'role', displayName: 'Role', sortable: false},
            {key: 'twofactor', displayName: '2FA', sortable: false},
            {key: 'updatedAt', displayName: 'UpdatedAt', sortable: false},
            {key: 'createdAt', displayName: 'CreatedAt', sortable: false},
            {key: 'actions', displayName: '', sortable: false},
          ]}
          renderRow={(item) => this.renderAccountUserRow(item)}
        />
        <h5>User Actions</h5>
        <Button
          color="blue"
          onClick={() => {
            history.push(`/account/${raw.id}/user/new`);
          }}
        >
          <i className="plus icon" />
          New
        </Button>
        <Button
          color="blue"
          onClick={() => {
            history.push(`/account/${raw.id}/user/existing`);
          }}
        >
          <i className="plus icon" />
          Existing
        </Button>
        <Modal
          open={this.state.showModal}
          onClose={() => this.setState({showModal: false})}
          trigger={
            <Button color="teal">
              <i className="envelope icon" />
              Invite
            </Button>
          }
        >
          <Modal.Content>
            <InviteUserForm onSubmit={(values) => this.onInviteUserFormSubmit(values)} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }

  renderAccountUserInvitations() {
    const {raw} = this.state;
    const {history} = this.props;

    return (
      <div>
        <APITable
          key="user_invitations_list"
          ref={(ref) => {
            this.apiTableRef = ref;
          }}
          apiPath={`/account/${raw.id}/invitation`}
          headers={[
            {key: 'email', displayName: 'Email', sortable: false},
            {key: 'role', displayName: 'Role', sortable: false},
            {key: 'updatedAt', displayName: 'UpdatedAt', sortable: false},
            {key: 'actions', displayName: '', sortable: false},
          ]}
          renderRow={(item) => this.renderAccountUserInvitationRow(item)}
        />
        <h5>User Actions</h5>
        <Button
          color="blue"
          onClick={() => {
            history.push(`/account/${raw.id}/user/new`);
          }}
        >
          <i className="plus icon" />
          New
        </Button>
        <Button
          color="blue"
          onClick={() => {
            history.push(`/account/${raw.id}/user/existing`);
          }}
        >
          <i className="plus icon" />
          Existing
        </Button>
        <Modal
          open={this.state.showModal}
          onClose={() => this.setState({showModal: false})}
          trigger={
            <Button color="teal">
              <i className="envelope icon" />
              Invite
            </Button>
          }
        >
          <Modal.Content>
            <InviteUserForm onSubmit={(values) => this.onInviteUserFormSubmit(values)} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }

  renderAccountDevices() {
    const {raw} = this.state;

    return (
      <div>
        <APITable
          key="devices_list"
          ref={(ref) => {
            this.apiTableRef = ref;
          }}
          apiPath={`/account/${raw.id}/device`}
          headers={[
            {key: 'name', displayName: 'Name', sortable: true},
            {key: 'isOnline', displayName: 'Online', sortable: true},
            {key: 'isInDevMode', displayName: 'Dev', sortable: true},
            {key: 'appVersion', displayName: 'Version', sortable: true},
            {key: 'connection', displayName: 'Connection', sortable: true},
            {key: 'manufacturer', displayName: 'Hardware', sortable: false},
            {key: 'shopId', displayName: 'Shop', sortable: true},
            {key: 'lastSeenAt', displayName: 'LastSeenAt', sortable: true},
            {key: 'updatedAt', displayName: 'UpdatedAt', sortable: true},
            {key: 'createdAt', displayName: 'CreatedAt', sortable: true},
            {key: 'actions', displayName: '', sortable: false},
          ]}
          renderRow={(item) => this.renderAccountDeviceRow(item)}
        />
        <Modal trigger={<Button color="blue">Screenshot Mosaic</Button>}>
          <Modal.Content image>
            <Mosaic accountId={raw.id} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }

  renderAccountAddons() {
    const {raw} = this.state;

    return (
      <div>
        <Modal
          open={this.state.showModal}
          onClose={() => this.setState({showModal: false})}
          trigger={
            <Button
              style={{margin: '10px'}}
              color="blue"
              floated="right"
              onClick={() => this.setState({showModal: true})}
            >
              <Icon name="plus" />
              Add new
            </Button>
          }
          closeIcon
        >
          <Modal.Header>Available Addons</Modal.Header>
          <Modal.Content image scrolling>
            <Addons accountId={raw.id} selectAddon={(addonId) => this.selectAccountAddon(addonId)} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              disabled={!this.state.selectedAddonToAdd}
              onClick={() => {
                this.addSelectedAddonToAccount();
              }}
            >
              Add to Account
            </Button>
          </Modal.Actions>
        </Modal>
        <APITable
          key="addons_list"
          ref={(ref) => {
            this.apiTableRef = ref;
          }}
          apiPath={`/account/${raw.id}/addons`}
          headers={[
            {key: 'name', displayName: 'Name'},
            {key: 'type', displayName: 'Type'},
            {key: 'purchasedAt', displayName: 'Purchased At'},
            {key: 'actions', displayName: ''},
          ]}
          renderRow={(item) => this.renderAccountAddonRow(item)}
        />
      </div>
    );
  }

  selectAccountAddon(addonId) {
    this.setState({
      selectedAddonToAdd: addonId,
    });
  }

  async addSelectedAddonToAccount() {
    if (!this.state.selectedAddonToAdd) return;

    await this.props.addonActions.addToAccount(this.state.selectedAddonToAdd, this.state.raw.id);
    this.apiTableRef.fetch();
    this.setState({showModal: false});
  }

  renderAccountReport() {
    const {report, packageLimitations} = this.state.raw;

    return (
      <Container className="vertical-padded-form-container">
        <Statistic.Group size="large" widths="four">
          <Statistic color={report.channelCount >= packageLimitations.channel ? 'red' : 'black'}>
            <Statistic.Value>
              {report.channelCount}/{packageLimitations.channel === 65536 ? '∞' : packageLimitations.channel}
            </Statistic.Value>
            <Statistic.Label>Channels</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{report.deviceCount}</Statistic.Value>
            <Statistic.Label>Devices</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>{report.contentCount}</Statistic.Value>
            <Statistic.Label>Contents</Statistic.Label>
          </Statistic>
          <Statistic color={report.userCount >= packageLimitations.user ? 'red' : 'black'}>
            <Statistic.Value>
              {report.userCount}/{packageLimitations.user === 65536 ? '∞' : packageLimitations.user}
            </Statistic.Value>
            <Statistic.Label>Users</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Container>
    );
  }

  renderAccountWidgets() {
    const {raw} = this.state;

    return (
      <div>
        <Modal
          open={this.state.showModal}
          onClose={() => this.setState({showModal: false})}
          trigger={
            <Button
              style={{margin: '10px'}}
              color="blue"
              floated="right"
              onClick={() => this.setState({showModal: true})}
            >
              <Icon name="plus" />
              Add new
            </Button>
          }
          closeIcon
        >
          <Modal.Header>Available Widgets</Modal.Header>
          <Modal.Content image scrolling>
            <Widgets accountId={raw.id} selectWidget={(widgetId) => this.selectAccountWidget(widgetId)} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              disabled={!this.state.selectedWidgetToAdd}
              onClick={() => {
                this.addSelectedWidgetToAccount();
              }}
            >
              Add to Account
            </Button>
          </Modal.Actions>
        </Modal>
        <APITable
          key="widgets_list"
          ref={(ref) => {
            this.apiTableRef = ref;
          }}
          apiPath={`/account/${raw.id}/widgets`}
          headers={[
            {key: 'name', displayName: 'Name'},
            {key: 'type', displayName: 'Type'},
            {key: 'purchasedAt', displayName: 'Purchased At'},
            {key: 'actions', displayName: ''},
          ]}
          renderRow={(item) => this.renderAccountWidgetRow(item)}
        />
      </div>
    );
  }

  selectAccountWidget(widgetId) {
    this.setState({
      selectedWidgetToAdd: widgetId,
    });
  }

  async addSelectedWidgetToAccount() {
    if (!this.state.selectedWidgetToAdd) return;

    await this.props.widgetActions.addToAccount(this.state.selectedWidgetToAdd, this.state.raw.id);
    this.apiTableRef.fetch();
    this.setState({showModal: false});
  }

  renderAccountActions() {
    let {importJobs = []} = this.props.account || {};

    // jobs might sometimes have missing data, just filter them out
    const displayedJobs = _.orderBy(
      importJobs.filter((job) => !!job.jobId),
      ['createdAt'],
      ['desc'],
    );
    const baseContentUrl = `${config.PANEL_PATH}/${this.state.raw.slug}/contents`;

    return (
      <Container className="tab-content-wrapper" fluid>
        <Container fluid>
          <Header as="h5">Import Content</Header>
          <ImportContentForm onSubmit={(values) => this.onImportContentFormSubmit(values)} />
          {displayedJobs.length > 0 && this.renderImportJobTable(baseContentUrl, displayedJobs)}
        </Container>
      </Container>
    );
  }

  renderVQActions() {
    let {vqImportJobs = []} = this.props.account || {};

    // jobs might sometimes have missing data, just filter them out
    const displayedJobs = _.orderBy(
      vqImportJobs.filter((job) => !!job.jobId),
      ['createdAt'],
      ['desc'],
    );
    const baseContentUrl = `${config.PANEL_PATH}/${this.state.raw.slug}/vq-contents`;

    return (
      <Container className="tab-content-wrapper" fluid>
        <Container fluid>
          <Header as="h5">Import Content</Header>
          <ImportContentForm onSubmit={(values) => this.onImportVQContentFormSubmit(values)} />
          {displayedJobs.length > 0 && this.renderImportJobTable(baseContentUrl, displayedJobs)}
        </Container>
      </Container>
    );
  }

  renderImportJobTable(baseContentUrl, jobs = []) {
    const iconProps = {
      new: {name: 'circle'},
      working: {name: 'circle notch', loading: true, color: 'blue'},
      successful: {name: 'check circle', color: 'green'},
      warning: {name: 'check circle', color: 'orange'},
      failed: {name: 'exclamation circle', color: 'red'},
    };
    return (
      <>
        <Header as="h6">Recent Imports</Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Job ID</Table.HeaderCell>
              <Table.HeaderCell>Original Content ID</Table.HeaderCell>
              <Table.HeaderCell>Result</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {jobs.map((job) => {
              let error = null;
              let result = null;

              try {
                if (job.state === 'failed') {
                  error = JSON.parse(job.error);
                } else if (job.state === 'successful') {
                  result = JSON.parse(job.result);
                }
              } catch (e) {
                console.log('error parsing job result', e);
              }

              const warnings = _.get(error || result, 'warnings') || [];
              const contentUrl = result && `${baseContentUrl}/${result.newContentId}/edit`;
              const icon = job.state === 'successful' && warnings.length ? iconProps.warning : iconProps[job.state];

              return (
                <Table.Row key={`import-job-${job.jobId}`}>
                  <Table.Cell>
                    <Icon {...icon} />
                    {job.state[0].toUpperCase() + job.state.slice(1)}
                  </Table.Cell>
                  <Table.Cell>{job.jobId}</Table.Cell>
                  <Table.Cell>{job.contentId}</Table.Cell>
                  <Table.Cell>
                    {job.state === 'failed' && (
                      <>
                        <p>
                          <strong>Job failed with the following error:</strong>
                        </p>
                        <p>
                          <strong>Step:</strong> {error.step}
                        </p>
                        <p>
                          <strong>Error:</strong> {error.message}
                        </p>
                      </>
                    )}
                    {job.state === 'successful' && (
                      <>
                        <p>
                          <strong>Import {warnings.length ? 'completed with warnings' : 'successful'}:</strong>
                        </p>
                        <p>
                          <a href={contentUrl} target="_blank" rel="noopener noreferrer">
                            {contentUrl}
                          </a>
                        </p>
                      </>
                    )}
                    {warnings.length > 0 && (
                      <>
                        <strong>Warnings:</strong>
                        <br />
                        <div>
                          <ul>
                            {_.map(_.groupBy(_.uniqBy(warnings, 'message'), 'stepName'), (group, groupName) => (
                              <>
                                <li>Step: {groupName}</li>
                                <ul>
                                  {group.map((warning) => (
                                    <li>{warning.message}</li>
                                  ))}
                                </ul>
                              </>
                            ))}
                          </ul>
                        </div>
                        <br />
                        <em>You may need to fix these warnings manually.</em>
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }

  renderSubAccounts() {
    const {raw} = this.state;

    return (
      <div>
        <APITable
          key="devices_list"
          ref={(ref) => {
            this.apiTableRef = ref;
          }}
          apiPath={`/account/${raw.id}/sub-accounts`}
          headers={[
            {key: 'name', displayName: 'Name', sortable: true},
            {key: 'package', displayName: 'Package', sortable: true},
            {key: 'status', displayName: 'Status', sortable: true},
            {key: 'updatedAt', displayName: 'UpdatedAt', sortable: true},
            {key: 'createdAt', displayName: 'CreatedAt', sortable: true},
            {key: 'deactivatedAt', displayName: 'DeactivatedAt', sortable: true},
          ]}
          renderRow={(item) => this.renderSubAccountsRow(item)}
        />
      </div>
    );
  }

  renderSubAccountsRow(item) {
    return (
      <Table.Row key={`subaccount_${item.id}`} onClick={(event) => this.onSubAccountClick(item, event)}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell collapsing>{item.package || 'unknown'}</Table.Cell>
        <Table.Cell>{item.status || 'unknown'}</Table.Cell>
        <Table.Cell>{moment(item.updatedAt).calendar()}</Table.Cell>
        <Table.Cell>{moment(item.createdAt).calendar()}</Table.Cell>
        <Table.Cell>{item.deactivatedAt ? moment(item.deactivatedAt).calendar() : ''}</Table.Cell>
      </Table.Row>
    );
  }

  onSubAccountClick(item) {
    const {history} = this.props;
    history.push(`/account/${item.id}`);
  }

  onTabChange(event, {activeIndex}) {
    const {history} = this.props;

    const activeTabType = this.panes[activeIndex].type;
    const tabName = Object.keys(AccountEditRoutePaths).find((key) => AccountEditRoutePaths[key] === activeTabType);
    history.push(`/account/${this.state.raw.id}/${tabName}`);
  }

  changeActiveTab(tabType) {
    const {history} = this.props;
    const tabIndex = this.panes.findIndex((pane) => pane.type === tabType);
    if (tabIndex === -1) return;

    const activeTabType = this.panes[tabIndex].type;
    const tabName = Object.keys(AccountEditRoutePaths).find((key) => AccountEditRoutePaths[key] === activeTabType);
    history.push(`/account/${this.state.raw.id}/${tabName}`);
  }

  render() {
    const {
      raw,
      showConfirmation,
      confirmationContent,
      showMessage,
      messageHeader,
      messageContent,
      messageColor,
      isNew,
    } = this.state;

    const tabName = this.props.match.params.tabName || 'edit';
    const activeTabType = AccountEditRoutePaths[tabName];
    const activeTabIndex = activeTabType ? this.panes.findIndex((pane) => pane.type === activeTabType) : 0;

    if ((!raw || !raw.id) && !isNew) {
      return <div>...</div>;
    }

    return (
      <div className="view">
        <h1>{raw && raw.name ? raw.name : '...'}</h1>
        <Tab
          panes={this.panes}
          activeIndex={activeTabIndex}
          onTabChange={(evt, payload) => this.onTabChange(evt, payload)}
        />
        <Confirm
          open={showConfirmation}
          content={confirmationContent}
          onCancel={() => this.confirmationCanceled()}
          onConfirm={() => this.confirmationApproved()}
        />
        <Message color={messageColor} hidden={!showMessage}>
          {messageHeader && <Message.Header>{messageHeader}</Message.Header>}
          {messageContent && <p>{messageContent}</p>}
        </Message>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  deviceActions: bindActionCreators(DeviceActions, dispatch),
  accountActions: bindActionCreators(AccountActions, dispatch),
  userActions: bindActionCreators(UserActions, dispatch),
  addonActions: bindActionCreators(AddonActions, dispatch),
  widgetActions: bindActionCreators(WidgetActions, dispatch),
  roleActions: bindActionCreators(RoleActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountEdit);
