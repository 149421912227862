import API from '../lib/api';
import {handleAPIError} from '../lib/utils';

export function addToAccount(addonId, accountId) {
  return (dispatch) =>
    API.getInstance()
      .post(`/account/${accountId}/addons/${addonId}`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function removeFromAccount(addonId, accountId) {
  return (dispatch) =>
    API.getInstance()
      .delete(`/account/${accountId}/addons/${addonId}`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}

export function listAvailables(accountId) {
  return (dispatch) =>
    API.getInstance()
      .get(`/account/${accountId}/addons/availables`)
      .then((response) => response.data)
      .catch(handleAPIError(dispatch));
}
