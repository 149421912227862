import React from 'react';
import {Link} from 'react-router-dom';

import {Menu, Icon} from 'semantic-ui-react';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_MAX_PAGE_COUNT = 9;

const itemProps = (path, onClick) => (page) => {
  if (path) {
    return {
      as: Link,
      to: path(page),
    };
  }

  if (onClick) {
    return {
      onClick: onClick.bind(null, page),
    };
  }

  return {};
};

const Pagination = ({
  path,
  onClick,
  floated = null,
  currentPage = 1,
  totalItems = 0,
  itemsPerPage = DEFAULT_PAGE_SIZE,
  maxDisplayedPageCount = DEFAULT_MAX_PAGE_COUNT,
}) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const displayedPageCount = Math.min(maxDisplayedPageCount, pageCount);

  const distance = Math.ceil(displayedPageCount / 2);

  const firstDisplayedPage = Math.max(1, currentPage - distance);
  const lastDisplayedPage = Math.min(pageCount, currentPage + distance);

  let displayedPages = [];

  for (let i = firstDisplayedPage; i <= lastDisplayedPage; i++) {
    displayedPages.push(i);
  }

  const showPreviousMarker = currentPage > 1;
  const showNextMarker = currentPage < pageCount;

  const propsFor = itemProps(path, onClick);

  return (
    <Menu floated={floated} pagination>
      {showPreviousMarker && (
        <Menu.Item key="table_pager_marker_prev" icon {...propsFor(currentPage - 1)}>
          <Icon name="left chevron" />
        </Menu.Item>
      )}
      {displayedPages.map((page) => (
        <Menu.Item key={`table_pager_marker_${page}`} active={currentPage === page} {...propsFor(page)}>
          {page}
        </Menu.Item>
      ))}
      {showNextMarker && (
        <Menu.Item key="table_pager_marker_next" icon {...propsFor(currentPage + 1)}>
          <Icon name="right chevron" />
        </Menu.Item>
      )}
    </Menu>
  );
};

export default Pagination;
